import React, { useState, useEffect } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import {
  courses_all_data,
  course_add,
  course_destroy,
} from "../../../apis/Course";
import CustomTableWithChild from "../CustomTableWithChild";
import { get_terms } from "../../../apis/Common";
import EditCourse from "./Edit";
import { AddTerm } from "./TermModal";
import { HTTPURL } from "../../../constants/Matcher";
import axios from "axios";
import { toast } from "react-toastify";

export const columns2 = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Course",
    selector: "name",
    sortable: true,
  },
  {
    name: "Course Type",
    selector: "CoursType",
    sortable: true,
  },
  {
    name: "Grade",
    selector: "grade",
    sortable: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
];


const AllCourses = () => {
  const [showResults, setShowResults] = useState(false);
  const [Course, setCourse] = useState([]);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const [allTerms, setAllTerms] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedTerms , setSelectedTerms] = useState([])

  const handleDeleteTerm = async(courseId , termId)=>{
  try {
      let responseData=   await axios({
          method: 'GET',
          url: HTTPURL + `api/course-term-distroy?term_id=${termId}&id=${courseId}`,
        });

       get_data();
        if (responseData.status) {
             toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
  } catch (error) {
     toast.error(error?.response?.data?.message || error?.message);
  }
}

let childColumns=[
  {
   name: 'S.No',
    selector: (row, index) => index + 1, 
    sortable: true ,
  },
  {
    name: 'Term',
    selector: row => row.name, 
    sortable: true,
  },
      {
        name: 'Action',
          name: 'Action',
    cell: row => (
      <div data-tag="allowRowEvents">
                        <Link class="edit-btn" 
                        to={`/admin/course-managment/course/edit/${row.course_id}/${row.id}/${row.course_type_id}/${row?.grade_id}` }
                      state={{ 
                         currentStepActive: 2,
                         isNotVisibleFirstStep:true
                        }}
                        >
                        <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button class="dlt-btn" onClick={() => {handleDeleteTerm(row.course_id ,row.id)}}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    }
]

 const get_data = async() =>{
  
      const result = await courses_all_data();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, status, course_type_id, grade, terms } = elem;
      console.log('terms',terms)
        return {
          no: `#${index + 1}`,
          name: name,
          status: status == "0" ? "INACTIVE" : "ACTIVE",
          CoursType: course_type_id ? course_type_id : "-",
          grade: grade,
          terms: terms,
          action: (
            <div data-tag="allowRowEvents">
              <Link
                class="edit-btn"
                to={`/admin/course-managment/course/edits/${id}`}
                 state={{ 
                         currentStepActive: 1,
                         isNotVisibleSecondStep:true
                        }}
              >
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </Link>
              <button class="dlt-btn" onClick={() => destroy(id)}>
                {" "}
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
              <button
                class="dlt-btn term-add"
         
                onClick={() => {
                  handleAddTermClick(id , terms);
                }}
              >
                {" "}
                Add Term
              </button>
            </div>
          ),
        };
      });

      setCourse(arrayData, setShowResults(true));
    }

     const destroy = (id) => {
      const result = window.confirm(
        "Are you sure you want to delete?"
      );
      if (result) {
        course_destroy(id);
      }
      get_data();
    };

  useEffect(() => {
   
    get_data();
  }, []);
    console.log("selectedCourseId--->" ,Course)

  const handleAddTermClick = async (id , alreadySelectedTerms) => {
    setIsModalOpen(true)
    setSelectedCourseId(id);
    setSelectedTerms(alreadySelectedTerms)
    console.log("selectedCourseId--->" ,alreadySelectedTerms)
   // selectedCourseData([])
    if (allTerms.length === 0) {
      const terms = await get_terms();
      setAllTerms(terms?.data);
    }
  };

  return (
    <>
      <div className="right-nav" id="dash-event">
        <div className="row">
          <div className="col-md-4">
            <h4 className="modal-title">Course List</h4>
          </div>
          <div className="col-md-8">
            <Link
              className="ui button float-end"
              to="/admin/course-managment/course/add"
            >
              Add Course
            </Link>
          </div>
        </div>
        {showResults ? (
          <CustomTableWithChild columns={columns2} data={Course} childColumns={childColumns} />
        ) : (
          <DataLoader />
        )}
      </div>
      <AddTerm setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} allTerms={allTerms}  selectedCourseId={selectedCourseId} selectedTerms={selectedTerms}/>
    </>
  );
};
export default AllCourses;

