import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader.jsx';
import {student_enroll_list} from '../../../api/Student_Enroll.jsx';
import CustomTable from '../../pages/CustomTable';
import { member_destroy } from "../../../../apis/studentManagement/StudentManagement";
import { formatDate } from '../../../../Utils/Utils.js';
export const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Name",
    selector: "first_name",
    sortable: true,
  },
  {
    name: "Grade",
    selector: "grade",
    sortable: true,
  },
  {
    name: "Branch",
    selector: "branch",
    sortable: true,
  },
  {
    name: "Course",
    selector: "course_name",
    sortable: true,
  },
  {
    name: "Sign In Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Status",
    selector: "delete_status",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
];
// const branch_id=localStorage.getItem('b_id')
// console.log('branch_id',branch_id);
const Student_enroll_list = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => 
    {
      const branch_id=localStorage.getItem('b_id')
        async function get_data() {
            const result = await student_enroll_list(branch_id);
            const arrayData = result?.data?.map((elem, index) => {
              const {
                id,
                first_name,
                last_name,
                subject,
                branch_name,
                grade_name,
                user_id,
                delete_status,
                course_name,
                created_at,
              } = elem;
              const courseNames = elem.course_name.split(",");
              console.log("ddd", courseNames);
      
              let primaryCourseName;
              if (courseNames.length > 1) {
                primaryCourseName = `${courseNames[0]}...`;
              } else {
                primaryCourseName = courseNames[0];
              }
              // const primaryCourseName = courseNames[0];
              const secondaryCourseNames = courseNames.slice(1);
              return {
                no: `#${index + 1}`,
                first_name:
                  first_name?.toLowerCase() + " " + last_name?.toLowerCase(),
                grade: `Year ${grade_name}`,
                subject: subject ? subject : "-",
                branch: branch_name,
                course_name: course_name ? (
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title={secondaryCourseNames.join(", ")}
                  >
                    {primaryCourseName}
                  </div>
                ) : (
                  "-"
                ),
                date: formatDate(created_at),
                delete_status: delete_status == "1" ? "Inactive" : "Active",
                action: (
                  <>
                    {" "}
                    <div data-tag="allowRowEvents">
                      <Link
                        className="edit-btn"
                        to={`/branch/student_enroll-view/${id}`}
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                      <Link
                        className="edit-btn"
                        to={`/branch/member-edit/${user_id}`}
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </Link>
                    </div>
                    <button
                      className="dlt-btn"
                      onClick={() => destroy(user_id, delete_status === 1 ? 0 : 1)}
                    >
                      {delete_status === 1 ? (
                        <i class="fa-solid fa-check"></i>
                      ) : (
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      )}{" "}
                    </button>
                  </>
                ),
              };
            });
            setData(arrayData, setShowResults(true));
          }
          const destroy = async (id, data) => {
            const dataa = {
              delete_status: data,
            };
            const result = window.confirm(
              `${
                data === 1
                  ? "Are you sure you want to inactive this user ?"
                  : "Are you sure you want to active this user ? "
              }`
            );
            if (result) {
              const res = await member_destroy(id, dataa);
              console.log("res", res);
              if (res?.data) {
                get_data();
              }
            }
            get_data();
          };
          get_data();         
    }, []);

    return (
      <div className="right-nav" id="dash-event">
        <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Student Enrolled List</h4>
                </div>
                {/* <div className='col-md-8'>
                    <Link className='ui button float-end' to="/branch/subject/add">Add Subject</Link>
                </div> */}
            </div>
      {
          showResults ?
          
          <CustomTable
              columns={columns}
              data={data}
          />
        : 
        <DataLoader />
      }
    </div>
    )
}

export default Student_enroll_list;