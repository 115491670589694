import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { course_add } from "../../../apis/Course";
import { Button } from 'semantic-ui-react';


export const AddTerm = ({ setIsModalOpen, isModalOpen, allTerms, selectedCourseId ,selectedTerms  }) => {
    const [allFilteredTerms, setAllFilteredTerms] = useState([]);
    const { register, handleSubmit,  formState: { errors } } = useForm();
    const navigate = useNavigate();

useEffect(()=>{
  
let filteredAll = allTerms?.filter(itemAll => !selectedTerms?.map(item => item.id).includes(itemAll.id));
  setAllFilteredTerms(filteredAll);
},[selectedCourseId, isModalOpen , allTerms])

    const onSubmit = async (data) => {
        data.course_id = selectedCourseId;
        let result = await course_add(data);
        if (result.status) {
            setIsModalOpen(false);
            localStorage.setItem("course_id", selectedCourseId);
            localStorage.setItem("totalUnits", data.totalUnits);
            localStorage.setItem("course_type", result.data?.data?.course_type);
            localStorage.setItem("term_id", result.data?.data?.term_id);

            navigate("/admin/course-managment/course/add/term", {
                state: { currentStep: 2, learningStep: 1 },
            });
        }
    };
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className="modal-content"
            overlayClassName="modal-overlay"
            contentLabel=" Modal"
        >
            <div className="modal-header">
                <h1 className="modal-title fs-5">Add Term</h1>
                <button 
                    type="button" 
                    onClick={() => setIsModalOpen(false)} 
                    className="btn-close" 
                    aria-label="Close"
                ></button>
            </div>
            <form className="modal-body" onSubmit={handleSubmit(onSubmit)}>
                <div className="terms selectbox">
                    <p>Terms</p>
                    <select className="form-control" {...register("term_id", { required: true })}>
                        <option value="">CHOOSE TERM</option>
                        {allFilteredTerms?.map((elem) => (
                            <option key={elem.id} value={elem.id}>
                                {elem.name}
                            </option>
                        ))}
                    </select>
                    {errors.term_id && errors.term_id.type === "required" && <span className="validation-error">This is required</span>}
                </div>
                <div className="terms">
                    <p>Total Units</p>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Total Unit"
                        {...register("totalUnits", { required: true })}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                    />
                      {errors.totalUnits && errors.totalUnits.type === "required" && <span className="validation-error">This is required</span>}
                </div>
                <div className="terms">
                    <p>Unit Price</p>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Unit Price"
                        {...register("price", { required: true })}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                    />
                    {errors.price && errors.price.type === "required" && <span className="validation-error">This is required</span>}
                </div>
                <div className="modal-footer">
                    <Button
                        type="button"
                        className="btn button"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button type="submit" className="btn button">
                        Save changes
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
