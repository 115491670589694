import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { branch_all } from "../../../../apis/Branches";
import {
  get_permissions,
  update_role,
  edit_role,
  delete_role
} from "../../../../apis/generalSetting/RolePermission";
import { useEffect, useState } from "react";
const EditRolePermission = ({ isModalOpen, setIsModalOpen, id }) => {
  const [branchlist, setBranchList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editPermissionsState, setEditPermissionsState] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState({});
  console.log(
    "editPermissionsStateeditPermissionsStateeditPermissionsState",
    editPermissionsState
  );
  // useEffect(() => {
  //   if (id) {
  //     const initialPermissions = {};
  //     permissions?.forEach((module) => {
  //       initialPermissions[module.id] = module.permission.map((perm) => ({
  //         permission_id: perm.id,
  //         permission_granted: perm.status ? 1 : 0,
  //       }));
  //     });
  //     setEditPermissionsState(initialPermissions);
  //   }
  // }, [id]);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    const fetchData = async () => {
      const res = await branch_all();
      const permission = await get_permissions();
      setBranchList(res?.data);
      setPermissions(permission?.data?.modules);
    };
    const editData = async () => {
      const res = await edit_role(id);
      setValue("role", res?.data?.rolePermission?.role);
      setValue("description", res?.data?.rolePermission?.description);
      setValue("branch_id", res?.data?.rolePermission?.branch_id);
      setEditPermissionsState(res?.data?.rolePermission?.modules);
      let AllRoleDetail = res?.data?.rolePermission
      if (AllRoleDetail && AllRoleDetail.modules) {
        const initialCheckedPermissions = {};
        AllRoleDetail.modules?.forEach(module => {
            initialCheckedPermissions[module.id] = {};
            module.permission?.forEach(permission => {
                initialCheckedPermissions[module.id][permission.permission_id] = permission.status;
            });
        });
        setCheckedPermissions(initialCheckedPermissions);
    }
    };
    if (id) {
      editData();
    }
    fetchData();
  }, [id]);
  // console.log("permissions", permissions,editPermissionsState);
  // console.log("editPermissionsState",editPermissionsState);
  // useEffect(() => {
  //   console.log(editPermissionsState,'editPermissionsStateeditPermissionsState');
    
  //   if (editPermissionsState.length > 0) {
  //     const initialPermissions = {};
  //     editPermissionsState.forEach((module) => {
  //       initialPermissions[module.id] = {};
  //       module.permission.forEach((permission) => {
  //         initialPermissions[module.id][permission.permission_id] = false;
  //       });
  //     });
  //     console.log(initialPermissions,'initialPermissionsinitialPermissionsinitialPermissions');
      
  //     setCheckedPermissions(initialPermissions);
  //   }
  // }, [editPermissionsState]);

  const toggleEditPermission = (moduleId, permId, isChecked) => {
    console.log(moduleId, permId, isChecked,'moduleId, permId, isChecked');
    
    setCheckedPermissions((prevState) => ({
      ...prevState,
      [moduleId]: {
        ...prevState[moduleId],
        [permId]: isChecked,
      },
    }));
  };

  const toggleAllEditPermissions = (moduleId, isChecked) => {
    setEditPermissionsState((prevState) => {
      const updatedState = { ...prevState };
      const allPermissions = permissions.modules.find(
        (m) => m.id === moduleId
      )?.permission;
      updatedState[moduleId] = allPermissions.map((perm) => ({
        permission_id: perm.id,
        permission_granted: isChecked ? 1 : 0,
      }));
      return updatedState;
    });
  };


  const onSubmit = async (data) => {
    const permissionsPayload = [];
    let isAnyPermissionSelected = false;
    
    editPermissionsState.forEach(module => {
        module.permission.forEach(permission => {
          if(checkedPermissions[module.id]?.[permission.permission_id]){
            permissionsPayload.push({
                permission_id: permission.permission_id,
                status: checkedPermissions[module.id]?.[permission.permission_id] ? 1 : 0
            });
          }
        });
    });
    const formData = {
      role: data.role,
      description: data.description,
      branch_id: data.branch_id,
      permissions: permissionsPayload,
    };

    console.log(formData,'formDataformDataformData');
    
    let result = await update_role(formData,id);
    if (result.status) {
      setIsModalOpen(false);
    }
};
const deleteButton=async()=>{
  const result = window.confirm("Are you sure you want to delete this role?");
  if (result) {
   const data=await delete_role(id);
   if(data.status){
    setIsModalOpen(false)
   }
  }

}
  console.log('checkedPermissions',checkedPermissions);
  
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        contentLabel="Edit Grade"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5"> Edit role</h1>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mt-3">
              <label className="label-txt">Role</label>
              <input
                type="text"
                placeholder="Manager"
                className="form-control"
                id="inputtext_1"
                {...register("role", { required: "true" })}
              />
              {errors.role && errors.role.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Branch list</label>
              <select
                className="form-select"
                {...register("branch_id", { required: "true" })}
              >
                <option value="">Choose Branch</option>
                {branchlist?.length > 0 ? (
                  branchlist &&
                  branchlist?.map((elem) => {
                    return (
                      <option value={elem?.id}>{elem?.branch_name}</option>
                    );
                  })
                ) : (
                  <option>No result</option>
                )}
              </select>
              {errors.branch_id && errors.branch_id.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Description</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="4"
                placeholder="Enter your message here..."
                {...register("description", { required: "true" })}
              ></textarea>
              {errors.description && errors.description.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
          </div>

          <div className="accordionss">
            {editPermissionsState?.map((module) => (
              <div className="accordion-item" key={module.id}>
                <h2 className="accordion-header" id={`heading${module.id}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${module.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${module.id}`}
                  >
                    <div className="accrd-heading">{module.module_name}</div>
                    <div className="checkbox-onoff">
                      <input
                        type="checkbox"
                        // checked={editPermissionsState[module.id]?.every(
                        //   (perm) => perm.permission_granted
                        // )}
                        onChange={(e) =>
                          toggleAllEditPermissions(module.id, e.target.checked)
                        }
                      />
                      <label
                        className="switch"
                        htmlFor={`toggle${module.id}`}
                      ></label>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${module.id}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`heading${module.id}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {module.permission.map((perm) => (
                      <div
                        className="accodion-body-inn"
                        key={perm.permission_id}
                      >
                        <div className="accrd-heading">{perm.permission_type}</div>
                        <div className="checkbox-onoff">
                          <input
                            type="checkbox"
                            id={`toggle${perm.permission_id}`}
                            checked={
                              checkedPermissions[module.id]?.[
                                perm?.permission_id
                              ] || false
                            }
                            onChange={(e) =>
                              toggleEditPermission(
                                module.id,
                                perm.permission_id,
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`toggle${perm.permission_id}`}
                          ></label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="deo-buttons_s">
            <button type="button" onClick={()=>deleteButton()} className="popup-btn" >
              {" "}
              Delete role
            </button>
            <button type="submit" className="popup-btn">
              {" "}
              Save Changes
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default EditRolePermission;
