import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import {
  get_all_website_env_data,
  get_all_year_data,
} from "../../../../apis/generalSetting/WebsiteEnv";
import CustomTable from "../../CustomTable";

export const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Year",
    selector: "year",
    sortable: true,
  },
  {
    name: "State",
    selector: "state",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
];

const AllWebsiteEnv = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [id, setId] = useState("");
  //check for previous year
  const Year = new Date().getFullYear();
  useEffect(() => {
    async function get_data() {
      const result = await get_all_website_env_data(id);
      const yearList = await get_all_year_data();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, Current_year, state } = elem;
        return {
          no: `#${index + 1}`,
          year: Current_year,
          state: state,
          action: (
            <div data-tag="allowRowEvents">
              {Current_year >= Year ? (
                <Link
                  class="edit-btn"
                  to={`/admin/administrator-setting/websiteEnv/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              <Link
                className="edit-btn"
                to={`/admin/administrator-setting/websiteEnv/view/${id}`}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </Link>
            </div>
          ),
        };
      });
      setYearData(yearList?.data);
      setData(arrayData, setShowResults(true));
    }
    get_data();
  }, [id]);
  return (
    <div className="right-nav" id="dash-event">
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Environment Variables</h4>
        </div>
        <div className="col-md-8">
          <Link
            className="ui button float-end"
            to="/admin/administrator-setting/websiteEnv/add"
          >
            Add Website Env
          </Link>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="form-group">
          {/* <label>Year</label> */}
          <select
            className="form-select form-control "
            name="branch"
            onChange={(e) => setId(e.target.value)}
          >
            <option value="">CHOOSE YEAR</option>
            {yearData?.length > 0 ? (
              yearData &&
              yearData?.map((elem) => {
                return (
                  <option value={elem?.current_year}>
                    {elem?.current_year}
                  </option>
                );
              })
            ) : (
              <option>No result</option>
            )}
          </select>
        </div>
      </div>
      {showResults ? (
        <CustomTable columns={columns} data={data} search={false} />
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllWebsiteEnv;
