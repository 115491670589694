import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
    },
    {
        name: 'Term',
        selector: 'term',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Page',
        selector: 'page',
        sortable: true,
    },
    {
        name: 'Modify',
        selector: 'modify',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    },
    {
        name: 'Play',
        selector: 'play',
        sortable: true,
    }
];

const FormativeAttendance = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [className, setClassName] = useState([]);

    const tableData = {
        columns,
        data,
    };

    const onChange = async (event) => {
        const value = event.target.value;
        let resClassData = await `get_data_class_id(value)`;
        setData(resClassData, setShowResults(true));
    };
    useEffect(() => {
        async function get_class_list() {
            const result = await `class_list_all()`;
            setClassName(result?.data);
        }
        get_class_list();

        async function get_data() {
            const result = await `class_details_all()`;
            const arrayData = result?.data?.map((elem, index) => {
                const { _id, class_id, book_page } = elem;

                return {
                    no: index + 1,
                    subject: class_id?.subject_id?.subject,
                    year: class_id?.year,
                    term: class_id?.term,
                    grade: class_id?.grade,
                    name: class_id?.c_name,
                    page: book_page,
                    modify: <div className='edit-btn'><Link className='dlt-btn' to={`/admin/class-detail/edit/${class_id?._id}`}>Modify</Link></div>,
                    action: <button className="dlt-btn" onClick={() => destroy(_id)}> Delete</button>,
                    play: '- - - -'
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                // class_detail_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='col-4-lg'>
                <Link className='ui button' to="/admin/class-detail/add">Add Class Detail</Link>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <label>Select a class:</label>
                        <select className='my-4 text-center' onChange={onChange} name="select_class_id">
                            <option value="">=============== 2023 Term 5 ===============</option>
                            {
                                className && className?.map((elem) => {
                                    return <option value={elem?._id}>{elem?.movie_title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            {
                showResults ?
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            paginationPerPage={30}
                            highlightOnHover
                        />
                    </DataTableExtensions>
                    :
                    <DataLoader />
            }
        </div>
    )
}

export default FormativeAttendance;

