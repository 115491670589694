import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { get_naplanCriteria, naplanCriteria_destroy } from '../../../../apis/testManagement/NaplanCriteria'
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'task',
        selector: 'task',
        sortable: true,
    },
    {
        name: 'criteria',
        selector: 'criteria',
        sortable: true,
    },
    {
        name: 'score',
        selector: 'score',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllnaplanCriteria = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    
    useEffect(() => {

        async function get_data() {
            const result = await get_naplanCriteria();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, task, criteria, score, description } = elem;

                return {
                    no: index + 1,
                    task: task,
                    criteria: criteria,
                    score: score,
                    action: <div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/admin/test-management/naplanCriteria-edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    <button className="dlt-btn" onClick={() => destroy(id)}>  <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                naplanCriteria_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Naplan Criteria List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/test-management/naplanCriteria-add">Add Naplan Criteria </Link>
                </div>
            </div>
            {
                showResults ?
                    
                        <CustomTable
                            noHeader
                            columns={columns}
                            data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllnaplanCriteria;


