import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";
import { Link } from 'react-router-dom';

export async function class_details_add(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/class-details-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all class-details 
export async function class_details_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/class-details-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-details edit 
export async function class_detail_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/class-details-edit/?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list update 
export async function class_detail_update(id,data)
{
    try {
        const response = await fetch(HTTPURL + `api/class-details-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list delete
export async function class_detail_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/class-details-destroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get data according to class id 
export async function get_data_class_id(id)
{
    try {
        const response = await fetch(HTTPURL + `api/class-detail-by-id/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        const arrayData = responseData?.data?.map((elem, index) => {
            const {_id,class_id,book_page} = elem;
            return {
              no: index + 1,
              subject: class_id?.subject_id?.subject,
              year:class_id?.year,
              term:class_id?.term,
              grade:class_id?.grade,
              name:class_id?.movie_title,
              page:book_page,
              modify:<div className='edit-btn'><Link className='dlt-btn' to={`/admin/class-detail/edit/${class_id?._id}`}>Modify</Link></div>,
              action: <button className="dlt-btn" onClick={() => destroy(_id)}> Delete</button>,
              play:'- - - -'
            };
        });
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                class_detail_destroy(id);
            }
          }
        return arrayData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}