import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Sidebar from "./Sidbar";
import Footer from "./Footer";
import "../../../css/admin.css";
import Logo from '../../../image/download.png';
const BranchLayout = () => {
    const [toggle, setToggle] = useState(false);
    const toggleClass = () => {
        setToggle(!toggle);
    };
    const fullname = localStorage.getItem("fullname");
    return (
        <div className="dash-backdrop">
            <nav className="sb-topnav navbar navbar-expand navbar-light bg-light justify-content-between">
                <div className="d-flex align-items-start">
                    <Link className="navbar-brand ps-3" to="dashboard" title="CS Online School">
                        <img src={Logo} alt="CS Education" title="CS Education" />
                    </Link>
                    <div className="side-bar-btn" onClick={() => toggleClass()}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>
                <h style={{fontSize:'17px'}}><b>Name:</b>{fullname}</h>
                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><Link to="/logout" className="dropdown-item"><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div id="layoutSidenav" className={toggle ? `toggle-full-width` : ''}>
                <Sidebar />
                <div id="layoutSidenav_content">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </div>
    )
};
export default BranchLayout;