import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { events_edit } from '../../../apis/Events';

const EventView = () => {
  const [eventData, setEventData] = useState({});
  const [show, setShow] = useState(false);
  const id = useParams();

  const fetchData = async () => {
    let res = await events_edit(id);
    const event = res?.data[0];
    setEventData(event);
    setShow(event?.event_type === 'interview');
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Event Details</h4>
          <Link className='edit-btn' to={`/admin/administrator-setting/events`}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
            <div class="event-detail-inner">
                <small>Event Type</small>
                <p>{eventData.event_type || 'N/A'}</p>
                </div>
                </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Title</small>
              <p>{eventData.title || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Date</small>
              <p>{eventData.date || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Time Start</small>
              <p>{eventData.time_start || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Time End</small>
              <p>{eventData.time_end || 'N/A'}</p>
            </div>
            </div>

            {show && (
              <div className="col-md-6">
                <div class="event-detail-inner">
                <small>Interview Time Limit</small>
                <p>{eventData.interview_time_limit || 'N/A'}</p>
              </div>
              </div>
            )}
            <div className="col-md-12">
            <div class="event-detail-inner sub">
              <small><b>Venue Details</b></small>
            </div>
            </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <hr className='mt-3' />
              <small>Venue Name</small>
              <p>{eventData.venue_name || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-6">
            <div class="event-detail-inner">
              <hr className='mt-3' />
              <small>Offline/Online Event</small>
              <p>{eventData.offline_online_event || 'N/A'}</p>
            </div>
            </div>

            {eventData.offline_online_event === 'offline' && (
              <>
                <div className="col-md-6">
                <div class="event-detail-inner">
                  <small>(Offline) Address</small>
                  <p>{eventData.offine_address || 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6">
                <div class="event-detail-inner">
                  <small>(Offline) Seats</small>
                  <p>{eventData.offine_seats || 'N/A'}</p>
                </div>
                </div>
              </>
            )}

            {eventData.offline_online_event === 'online' && (
              <>
                <div className="col-md-6">
                <div class="event-detail-inner">
                  <small>(Online) URL</small>
                  <p>{eventData.online_url || 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6">
                <div class="event-detail-inner">
                  <small>(Online) Seats</small>
                  <p>{eventData.online_seats || 'N/A'}</p>
                  </div>
                </div>
              </>
            )}

            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Description</small>
              <p>{eventData.description || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-12">
            <div class="event-detail-inner sub">
              <hr className='mt-3' />
              <small><b>Booking Date</b></small>
            </div>
            </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Start</small>
              <p>{eventData.booking_date_start || 'N/A'}</p>
            </div>
            </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>End</small>
              <p>{eventData.booking_date_end || 'N/A'}</p>
            </div>
            </div>

            <div className="col-md-12">
            <div class="event-detail-inner sub">
              <small><b>Break (if applicable)</b></small>
            </div>
            </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Break Start</small>
              <p>{eventData.break_start || ''}</p>
            </div>
            </div>
            <div className="col-md-6">
            <div class="event-detail-inner">
              <small>Break End</small>
              <p>{eventData.break_end || 'N/A'}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventView;
