import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// add discout
export async function discout_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/discount-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status) 
                {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all discout
export async function discout_all() {
    try {
        const response = await fetch(HTTPURL + 'api/discount-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;  
    }
}

// discout edit 
export async function discout_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/discount-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// discout update 
export async function discout_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/discount-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// discout delete
export async function discout_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/discount-destroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
