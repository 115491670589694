import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addSubUser } from "../../../../apis/generalSetting/SubUsers";
import { branch_all, role_by_branch } from "../../../../apis/Branches";
import { handleNavigation } from "../../../../Utils/Utils";
const AddUsers = () => {
  const [branchlist,setBranchList] = useState([]);
  const [rolelist,setRoleList] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors,isDirty }
  } = useForm();

  const navigate = useNavigate();
 
    useEffect(()=>{
    const fetchData=async()=>{
        const res=await branch_all();
        const roleList = await role_by_branch(branchId)
        setBranchList(res?.data)
        setRoleList(roleList?.data)
    }
    fetchData();
  },[branchId])
  const onSubmit = async (data) => {
      console.log(data, getValues('role'), getValues('role_id'));
      
      let result = await addSubUser(data);
      if (result.status) {
        return navigate("/admin/administrator-setting/sub-users/all");
      }
     // reset();
  };

  const handleSelectRole =(id) => {
    setSelectedRoleId(Number(id))
    let selected = rolelist && rolelist.find((item)=> item.role_id === Number(id));
    if(selected){
      setValue('role_id', selected.role_id)
      setValue('role', selected.role)

    }
  }

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-users">
              <h4 className="modal-title">Add User</h4>
              <Link
                className="edit-btn"
                to={`/admin/administrator-setting/sub-users/all`}
                onClick={(e) => {handleNavigation(e,isDirty)}}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="permission-sec-n">
              {/* <h4 className="modal-title">Permissions</h4> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>First Name *</label>
                            <input
                              type="text"
                              placeholder="Enter First Name"
                              className="form-control"
                              {...register("first_name", { required: true })}
                            />
                          {errors.first_name &&
                            errors.first_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              placeholder="Enter Last Name"
                              className="form-control"
                              {...register("last_name", { required: true })}
                            />
                          {errors.last_name &&
                            errors.last_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email *</label>
                            <input
                              type="email"
                              placeholder="Enter Email"
                              className="form-control"
                              {...register("email", { required: true })}
                              autocomplete="off"
                            />
                          {errors.email && errors.email.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Password *</label>
                            <input
                              type="password"
                              placeholder="Enter Password"
                              className="form-control"
                              {...register("password", { required: true })}
                              autocomplete="one-time-code"
                            />
                          {errors.password &&
                            errors.password.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Mobile No. *</label>
                            <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                            <input
                              type="text"
                              placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                              className="form-control"
                              {...register("mobile", { 
                                required: "Mobile number  is required",
                          pattern: { value: /^\d{10}$/, message: "mobile number must be 10 digits" },
                          validate: (value) =>
                            /^\d+$/.test(value) || "Only numbers are allowed",
                         })}
                         maxLength={10}
                            />
                            </div>
                          {errors.mobile && (
                              <span className="validation-error">
                                 {errors.mobile.message}
                              </span>
                            )}
                          </div>
                        </div>
                        
                          
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Branch *</label>
                            <select
                              className="form-select"
                              {...register("branch_id",{required:'true'})}
                              onChange={(e) => setBranchId(e.target.value)}
                            >
                              <option value="">Choose Branch</option>
                              {branchlist?.length > 0 ? (
                                branchlist &&
                                branchlist?.map((elem) => {
                                  return (
                                    <option value={elem?.id}>
                                      {elem?.branch_name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option>No result</option>
                              )}
                            </select>
                            {errors.branch_id &&
                            errors.branch_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group  add_new">
                            <label>Roles *</label>
                            <Link href="" title="Add new role" className="add_role" to="/admin/administrator-setting/role-permission/all">Add new role</Link>
                            <select
                              className="form-select"
                              {...register("role_id",{required:'true'})}
                              value={selectedRoleId}
                              onChange={(e)=> handleSelectRole(e.target.value)}
                            >
                               {rolelist?.length > 0
                        ? [
                            <option value="">Choose Role</option>,
                            rolelist?.map((elem) => {
                              return (
                                <option value={elem?.role_id}>
                                  {elem?.role}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">Role not available</option>,
                          ]}
                            </select>
                            {errors.role_id &&
                            errors.role_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>



                       
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Status *</label>
                            <select
                              className="form-select"
                              {...register("status")}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                             
                            </select>
                            {errors.branch_id &&
                            errors.branch_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>


                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <label>status</label>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                {...register("status", { required: true })}
                                id="inlineRadio1"
                                value={1}
                                defaultChecked
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                {...register("status", { required: true })}
                                id="inlineRadio2"
                                value={0}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                          {errors.status &&
                            errors.status.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="col-md-6 checkbox-n">
                      <div className="row">
                        <div className="col-md-4">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={payment}
                            onChange={handleChange}
                          />{" "}
                          <span className="modal-title">Payment</span>
                        </div>
                        <div className="col-md-12">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={classes}
                            onChange={handleChange1}
                          />{" "}
                          <span className="modal-title">Branch</span>
                        </div>
                        <div className="col-md-12">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={payment}
                            onChange={handleChange2}
                          />{" "}
                          <span className="modal-title">Classes</span>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUsers;
