import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { branch_all, branch_destroy } from '../../../api/branches/Branch';
import CustomTable from '../../pages/CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Branch Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Branch Code',
        selector: 'branch_code',
        sortable: true,
    },
    {
        name: 'Branch Contact No',
        selector: 'b_contact_no',
        sortable: true,
    },
    // {
    //     name: 'Delete',
    //     selector: 'action',
    //     sortable: true,
    // },
];

const AllBrances = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await branch_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, branch_name, branch_code, Branch_contact_number } = elem;
                return {
                    no: `#${index + 1}`,
                    name: branch_name.toLowerCase(),
                    branch_code: branch_code,
                    b_contact_no: Branch_contact_number,
                    action:<div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/branch/edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    <button className="dlt-btn" onClick={() => destroy(id)}>  <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                branch_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Branch List</h4>
                </div>
                <div className='col-md-8'>
                    {/* <Link className='ui button float-end' to="/branch/add">Add Branch</Link> */}
                </div>
            </div>
            {
                showResults ?
                    <CustomTable columns={columns} data={data} />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllBrances;


