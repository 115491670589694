import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  branch_add_question_time,
  branch_edit_question_time,branch_question_time_update
} from "../../../api/branches/Branch";
import Modal from "react-modal";
const QuestionAdd = ({
  isModalOpen,
  setIsModalOpen,
  question_id,
  type,
  setType,
}) => {
  const userId = JSON.parse(localStorage.getItem("userData"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if(type ==='edit'){
        let result = await branch_question_time_update(question_id,data);
        if (result?.status) {
          setType("");
          {
            setIsModalOpen(false);
          }
          return navigate("/branch/question-list");
        }
    }else{
        data.question_id = question_id;
        data.user_id = userId.data?.id;
     
        let result = await branch_add_question_time(data);
        if (result?.status) {
          setType("");
          {
            setIsModalOpen(false);
          }
          return navigate("/branch/question-list");
        }
    }
  };
  const fetchData = async () => {
    let res = await branch_edit_question_time(question_id);
    setValue("start_time", res?.data?.start_time);
    setValue("end_time", res?.data?.end_time);
  };

  useEffect(() => {
    if (question_id && type === "edit") {
      fetchData();
    }
  }, [question_id, type]);

  // const maxDate = new Date();
  // maxDate.setFullYear(maxDate.getFullYear() );
  // const TodayDate = maxDate.toISOString().split('T')[0];

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());
  const TodayDate = maxDate.toISOString().split("T")[0] + "T00:00";
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {setIsModalOpen(false);setType('');reset()}}
        contentLabel="Edit Grade"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
            <div className="modal-header">
              <h5 className="mx-auto modal-title mt-2">
              {type === 'edit' ? 'Edit' :"Add"} Schedule for Questionnaire{" "}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setIsModalOpen(false);
                  setType("");
                  reset()
                }}
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Start Time</label>
                      <input
                        type="datetime-local"
                        {...register("start_time", { required: true })}
                        min={TodayDate}
                        className="form-control"
                        pattern="dd-mm-yyyy"
                      />
                    </div>
                    {errors.start_time &&
                      errors.start_time.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>End Time</label>
                      <input
                        type="datetime-local"
                        {...register("end_time", { required: true })}
                        min={TodayDate}
                        className="form-control"
                        pattern="dd-mm-yyyy"
                      />
                    </div>
                    {errors.end_time && errors.end_time.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
      </Modal>
    </>
  );
};
export default QuestionAdd;
