import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CustomTable from '../CustomTable';
import DataLoader from '../../../Utils/DataLoader';
import { google_class_list ,google_class_list_destroy} from '../../../apis/ClassManagement/GoogleClass';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Branch',
        selector: 'branch_name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Days',
        selector: 'days',
        sortable: true,
    },
    {
        name: 'Class',
        selector: 'movie_title',
        sortable: true,
    },
    {
        name: 'Time',
        selector: 'time',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    }
];

const GoogleClassList = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
        
    useEffect(() => {
        async function get_data() {
            const result = await google_class_list();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, time_start, time_end, google_meet_code, movie_title, branch_name, grade, days  } = elem;
                return {
                    no: index + 1,
                    branch_name: branch_name,
                    grade: grade,
                    days: days,
                    movie_title:movie_title,
                    time: `${time_start} - ${time_end}`,
                    action: <button className="dlt-btn" onClick={() => destroy(id)}> Delete</button>,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete  ?");
            if (result) {
                google_class_list_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Google Class List</h4>
                    </div>
                </div>
                {
                    showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                        :
                        <DataLoader />
                }
            </div>
        </>
    )
}
export default GoogleClassList;


