import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Free_Assesment_add } from "../../../../apis/Assesment.jsx";
import { get_question_by_type } from "../../../../apis/testManagement/Question.jsx";
import { grade_all } from "../../../../apis/Grade";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../../Utils/Utils.js";
const AddFreeAssesment = () => {
  const [question, setQuestion] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [gradeID, setGradeID] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    data.title = "";
    let result = await Free_Assesment_add(data);
    if (result.status) {
      return navigate("/admin/course-managment/assesment/all-free");
    }
    reset();
    setIsSubmitting(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        questionnaire_type: "Free Assessment Test",
        grade_id: gradeID,
      };
      const questionList = await get_question_by_type(payload);
      const gradeList = await grade_all();
      setQuestion(questionList?.data);
      setGradeList(gradeList?.data);
    };
    fetchData();
  }, [gradeID]);
  const handleGradeChange = (e) => {
    setGradeID(e.target.value);
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Free Assesment</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/course-managment/assesment/all-free`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <form
          className="form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Grade *</label>
                <select
                  className="form-select form-control"
                  {...register("grade_id", { required: true })}
                  onChange={(e) => {handleGradeChange(e);register("grade_id").onChange(e); }}   
                >
                  <option value="">Choose Grade</option>
                  {gradelist?.length > 0 ? (
                    gradelist &&
                    gradelist?.map((elem) => {
                      return <option value={elem?.id}>{elem?.name}</option>;
                    })
                  ) : (
                    <option>No result</option>
                  )}
                </select>
                {!gradeID &&
                  errors.grade_id &&
                  errors.grade_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Questionaire *</label>
                <select
                  className="form-control form-select"
                  {...register("question_id", { required: true })}
                >
                  <option value="">Choose Question</option>
                  {question &&
                    question?.map((elem) => {
                      return (
                        <option value={elem?.id}>{elem?.question_name}</option>
                      );
                    })}
                </select>
                {errors.question_id &&
                  errors.question_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>status</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    {...register("status", { required: true })}
                    id="inlineRadio1"
                    value="1"
                    defaultChecked
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    {...register("status", { required: true })}
                    id="inlineRadio2"
                    value="0"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Inactive
                  </label>
                </div>
              </div>
              {errors.status && errors.status.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn button"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddFreeAssesment;
