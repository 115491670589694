export const CustomValidations = (step, data,show) => {
    console.log('validationside',show)
    const validationErrors = {};
    switch (step) {
        case 1:
            if (!data.test_type) {
                validationErrors.test_type = 'This is requierd.';
            }
            if (!data.category_id) {
                validationErrors.category_id = 'This is requierd.';
            }
            if (!data.sub_category_id) {
                validationErrors.sub_category_id = 'This is requierd.';
            }
            if (!data.question) {
                validationErrors.question = 'This is requierd.';
            }
            if (!data.option1 && !show) {
                validationErrors.option1 = 'This is requierd.';
            }
            if (!data.option2 && !show) {
                validationErrors.option2 = 'This is requierd.';
            }
            if (!data.option3 && !show) {
                validationErrors.option3 = 'This is requierd.';
            }
            // if (!data.option4 && !show) {
            //     validationErrors.option4 = 'This is requierd.';
            // }
            // if (!data.option5 && !show) {
            //     validationErrors.option5 = 'This is requierd.';
            // }
            if (!data.answer && !show) {
                validationErrors.answer = 'This is requierd.';
            }
            break;
        default:
            break;
    }
    return validationErrors;
}