import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { teacher_add } from '../../../api/Teacher';
import { branchList, subjectList, gradeList } from './Data';
import { insertId } from '../../../api/common';
import { random_generate_string } from '../../../../Utils/Utils'
import Select from 'react-select';

const AddTeacher = () => {
    const [branchlist, setBranchList] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [subjectlist, setSubjectList] = useState([]);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const [selectedGradOptions, setSelectedGradOptions] = useState([]);
    const [randomText, setRandomText] = useState('');
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };
    const {
        register,
        control,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const branch_name = localStorage.getItem('b_id')

 
    const text_relaod = () => {
        setRandomText(random_generate_string(8));
    }

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const formData = { branch: data.branch, teacher_id: data.teacher_id, teacher_name: data.teacher_name, status: data.status, grade: selectedGradOptions, subject: selectedSubOptions, password: randomText.trim(), email: data.email }
        let result = await teacher_add(formData);
        if (result.status) {
            return navigate('/branch/teacher/all');
        }
    }
    const handleGradChange = (selectedGradOptions) => {
        setSelectedGradOptions(selectedGradOptions);
    };

    const handleSubChange = (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
    };

    const fetchData = async () => {
        let res = await insertId();
        setValue('teacher_id', `TEACH${res?.data?.id ?? 1}`);
    }

    useEffect(() => {
        async function get_all_data() {
            setBranchList(await branchList());
            setGradeList(await gradeList());
            setSubjectList(await subjectList());
            setValue('branch',branch_name)
        }
        get_all_data();
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Teacher</h4>
                    <Link className='edit-btn' to={`/branch/teacher/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Branch Name</label>
                                    <select className="form-select form-control" {...register("branch")} disabled>
                                        <option value="">Choose Branch</option>
                                        {branchlist?.length > 0 ?
                                            branchlist && branchlist?.map((elem) => (
                                                <option key={elem} value={elem?.id}>{elem?.branch_name}</option>
                                            ))
                                            : <option>No result</option>
                                        }
                                    </select>
                                </div>
                                
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Teacher ID </label>
                                    <input type="text" className="form-control" {...register('teacher_id')}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Teacher Name</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Teacher Name'
                                        {...register('teacher_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Grade</label>
                                    <Controller
                                        name="grade"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Select
                                                options={gradelist}
                                                className="form-control"
                                                placeholder='Choose Grade'
                                                isMulti
                                                {...field}
                                                value={selectedGradOptions}
                                                onChange={(otopn) => handleGradChange(otopn)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Select
                                                options={subjectlist}
                                                className="form-control"
                                                placeholder='Choose Subject'
                                                isMulti
                                                {...field}
                                                value={selectedSubOptions}
                                                onChange={(otopn) => handleSubChange(otopn)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" {...register('status')} id="inlineRadio1" value="1" />
                                        <label className="form-check-label" for="inlineRadio1">Active</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" {...register('status')} id="inlineRadio2" value="0" />
                                        <label className="form-check-label" for="inlineRadio2">Inavtive</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Email'
                                        {...register('email')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="relod_cont">
                                    <label >Password</label>
                                    <input type={!isVisible ? "password" : "text"}
                                        placeholder='Enter Password'
                                        className="form-control" name="captcha"
                                        value={randomText.trim()}
                                        {...register('password')} />
                                </div>
                                <div onClick={() => text_relaod()} className="btn btn-primary generate-pasword">generate password</div>
                                <span className="show-hide" onClick={toggle}>
                                    {isVisible ? <span>Hide</span> : <span>Show</span>}
                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddTeacher;
