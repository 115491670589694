import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { update_state,edit_state } from "../../../../apis/States";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../../Utils/Utils";
const EditState = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const id = useParams();
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await update_state(id, data);
    if (result.status) {
      return navigate("/admin/administrator-setting/state/all");
    }
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let res = await edit_state(id);
    console.log('resres',res?.data);
    
    setValue("name", res?.data[0]?.name);
    setValue("status", res?.data[0]?.status?.toString());
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Edit State </h4>
          <Link
            className="edit-btn"
            to={`/admin/administrator-setting/state/all`}
            onClick={(e) => {
              handleNavigation(e, isDirty);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>State Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter State Name"
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name && errors.name.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditState;
