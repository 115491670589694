import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

export async function get_permissions() {
    try {
        const response = await fetch(HTTPURL + 'api/permission-data');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function addRole(data) {
    try {
        const response = await fetch(HTTPURL + 'api/rol-permision-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_roles() {
    try {
        const response = await fetch(HTTPURL + 'api/rol-permission-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function edit_role(id) {
    try {
        const response = await fetch(HTTPURL + `api/rol-permission-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status === true) {
            // toast.success(responseData?.message);
        } else if (responseData?.status === false) {
            toast.success(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function update_role(data,id) {
    try {
        const response = await fetch(HTTPURL + `api/rol-permission-update?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function delete_role(id) {
    try {
        const response = await fetch(HTTPURL + `api/rol-permsion-distroy?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_role_by_branch(id) {
    try {
        const response = await fetch(HTTPURL + `api/role-by-branch?id=${id}`);
        const responseData = await response.json();
        if (responseData.status === true) {
            // toast.success(responseData?.message);
        } else if (responseData?.status === false) {
            toast.success(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}