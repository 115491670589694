import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { google_class_list, google_class_list_destroy } from '../../../apis/ClassManagement/GoogleClass';
import { class_detail_destroy, class_details_all, get_data_class_id } from '../../../apis/ClassManagement/ClassDetails';
import { class_list_all, class_list_destroy } from '../../../apis/ClassManagement/ClassList';
import EditGrade from '../grades/Edit'
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
    },
    {
        name: 'Term',
        selector: 'term',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

export const columns1 = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];



export const columns2 = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Branch',
        selector: 'branch_name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Days',
        selector: 'days',
        sortable: true,
    },
    {
        name: 'Class',
        selector: 'movie_title',
        sortable: true,
    },
    {
        name: 'Time',
        selector: 'time',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const ClassList = () => {
    const [classList, setClassList] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [data, setData] = useState([]);
    const [className, setClassName] = useState([]);
    const [googleClass, setGoogleClass] = useState([]);
    const [status, setStatus] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    useEffect(() => {

        async function get_data() {
            const result = await class_list_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, subject, year, term, grade, c_name } = elem;

                return {
                    no: index + 1,
                    subject,
                    year,
                    term,
                    grade,
                    name: c_name,
                    action: <> <button onClick={() => {
                        setDuplicateItemId(id); setIsModalOpen(true);
                        setStatus('classList')
                    }}
                        className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Modify</button>
                        <button className="dlt-btn" onClick={() => destroy(id)}> Delete</button></>
                };
            });
            setClassList(arrayData, setShowResults(true));
            setClassName(result?.data)
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                class_list_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    const onChange = async (event) => {
        const value = event.target.value;
        let resClassData = await get_data_class_id(value);
        setData(resClassData, setShowResults(true));
    };
    useEffect(() => {
        // async function get_class_list() {
        //     const result = await class_list_all();
        //     setClassName(result?.data);
        // }
        // get_class_list();

        async function get_data() {
            const result = await class_details_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, subject, book_page, movie_name } = elem;

                return {
                    no: index + 1,
                    subject: subject,
                    name: movie_name,
                    action: <> <button onClick={() => {
                        setDuplicateItemId(id); setIsModalOpen(true);
                        setStatus('classDetail')
                    }}
                        className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Modify</button>
                        <button className="dlt-btn" onClick={() => destroy(id)}> Delete</button></>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete  ?");
            if (result) {
                class_detail_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    useEffect(() => {
        async function get_data() {
            const result = await google_class_list();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, time_start, time_end, google_meet_code, movie_title, branch_name, grade, days } = elem;
                return {
                    no: index + 1,
                    branch_name: branch_name,
                    grade: grade,
                    days: days,
                    movie_title: movie_title,
                    time: `${time_start} - ${time_end}`,
                    action: <button className="dlt-btn" onClick={() => destroy(id)}> Delete</button>,
                };
            });
            setGoogleClass(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                google_class_list_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="modal-content">
            <div className="modal-body">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Classes</button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Class Detail</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Google Class</button>
                    </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="right-nav" id="dash-event">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <h4 className='modal-title'>Classes</h4>
                                </div>
                                <div className='col-md-8'>
                                    <Link className='ui button float-end' to="/admin/class-list/add">Add Class
                                    </Link>
                                </div>
                            </div>
                            {
                                showResults ?
                                    <CustomTable
                                        columns={columns}
                                        data={classList}
                                    />
                                    :
                                    <DataLoader />
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="right-nav" id="dash-event">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <h4 className='modal-title'>Class Details List</h4>
                                </div>
                                {/* <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/class-detail/add">Add Class Detail</Link>
                </div> */}
                            </div>
                            <div className='row'>
                                <div className='col-md-3 col-sm-12'>
                                    <div className='form-group mt-4'>
                                        <label>Select a class:</label>
                                        <select className='form-select form-control' onChange={onChange} name="select_class_id">
                                            <option value="">Choose Class Name</option>
                                            {
                                                className && className?.map((elem) => {
                                                    return <option value={elem?.id}>{elem?.movie_title}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {
                                showResults ?

                                    <CustomTable
                                        columns={columns1}
                                        data={data}
                                    />
                                    :
                                    <DataLoader />
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="right-nav" id="dash-event">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <h4 className='modal-title'>Google Class List</h4>
                                </div>
                            </div>
                            {
                                showResults ?
                                    <CustomTable
                                        columns={columns2}
                                        data={googleClass}
                                    />
                                    :
                                    <DataLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <EditGrade duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </div>

    )
}
export default ClassList;



