import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import CustomTable from '../../CustomTable';
import { get_comments, comment_destroy } from '../../../../apis/testManagement/writingComment';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Item',
        selector: 'an_item',
        sortable: true,
    },
    {
        name: 'Score',
        selector: 'score',
        sortable: true,
    },
    {
        name: 'Description',
        selector: 'comment_description',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllWritingComments = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
   
    useEffect(() => {
        async function get_data() {
            const result = await get_comments() ;
            const arrayData = result?.data?.map((elem, index) => {
                const { id, an_items, score, comment_description } = elem;

                return {
                    no: index + 1,
                    an_item: an_items,
                    score: score,
                    comment_description: comment_description,
                    action: <>
                    <Link className='edit-btn' to={`/admin/test-management/writing-edit/${id}`}>Modify</Link><button className="dlt-btn" onClick={() => destroy(id)}> Delete</button>
                    </>,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                comment_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Comments List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/test-management/writing-add">Add Comments</Link>
                </div>
            </div>
            {
                showResults ?
                    
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllWritingComments;


