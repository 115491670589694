import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { sub_users_list, sub_users_update } from '../../../../apis/generalSetting/SubUsers';
import CustomTable from '../../pages/CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
    },
    {
        name: 'Mobile No',
        selector: 'number',
        sortable: true,
    },
    {
        name: 'Branch Name',
        selector: 'Branch',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    },
  
    // {
    //     name: 'Permission',
    //     selector: 'permission',
    //     sortable: true,
    // },
];

const UserList = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const onChange = async (event, id) => {
        const value = event.target.value;
        setSelectedStatus(value);
        console.log(id, value);
        let resClassData = await sub_users_update(id, value);
        get_data()
    };
    async function get_data() {
        const result = await sub_users_list();
        const arrayData = result?.data?.map((elem, index) => {
            const { id, first_name, last_name, email, mobile, payment, branch, c_name, status,branch_name } = elem;
            if (result?.data.length > 0 && selectedStatus === null) {
                setSelectedStatus(status);
            }
            return {
                no: `#${index + 1}`,
                name: `${first_name} ${last_name}`,
                email: email,
                number: mobile ? mobile : '-',
                Branch: branch_name ? branch_name : '-',
                status: ` ${status == 1 ? 'Active' : 'Inactive'}`,
                permission: `payemnt:${payment == 1 ? 'Yes' : 'NO'},
                                 branch:${branch == 1 ? 'Yes' : 'NO'},
                                 Class:${c_name == 1 ? 'Yes' : 'NO'}`,
                action: <div data-tag="allowRowEvents">
                    <Link class="edit-btn" to={`/admin/administrator-setting/sub-users/edit/${id}`}>
                        <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    </div>
            };
        });
        setData(arrayData, setShowResults(true));
    }
    useEffect(() => {
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Users List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/administrator-setting/sub-users/add">Add User</Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable columns={columns} data={data} />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default UserList;


