// import React, { useEffect, useState } from 'react'
// import { get_question } from '../../../../apis/testManagement/Question.jsx';
// import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
// import AddSelfStudy from './Add.jsx';
// const EditSelfStudy = ({ setSelfStudy, selfStudy,setSelfStudyData,selfStudyData }) => {

//     const [subjects, setSubject] = useState([]);
//     const [question, setQuestion] = useState([]);
//     useEffect(() => {
//         const fetchData = async () => {
//             const questionList = await get_question();
//             const result = await subject_all();
//             setQuestion(questionList?.data);
//             setSubject(result?.data);
//         };
//         fetchData();
//     }, []);
  

//     //SelfStudy handle change for image
//     const handleSelfStudyDataChange = (index, fieldname, value) => {
//         console.log('fieldname',fieldname,value);
//         if (fieldname == 'subject_id') {
//             localStorage.setItem('courseSubjectID', value)
//         }
//         const updatedData = [...selfStudyData];
//         updatedData[index] = { ...updatedData[index], [fieldname]: value };
//         setSelfStudyData(updatedData);
//     };
//     //lecture handle change for image
//     const handleSelfStudyImgChange = (e, index) => {
//         const { name, files } = e.target;
//         const list = [...selfStudyData];
//         list[index][name] = files[0];
//         setSelfStudyData(list);
//     }
   
//     return (
//         <>
           
//            {
//                 selfStudyData && selfStudyData.length > 0 ?

//                 selfStudyData && selfStudyData?.map((x, i, data) => {
//                     return (
//                         <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
//                             <div className="modal-body">
//                                 <div className="row">
//                                     <div className="col-md-6" key={i}>
//                                         <div className="form-group">
//                                             <label>Questionaire {i + 1}</label>
//                                             <select className="form-control form-select"
//                                                  value={x?.q_id} name='q_id'
//                                                 onChange={(e) => handleSelfStudyDataChange(i, 'q_id', e.target.value)}>
//                                                 <option value="">Choose Question</option>
//                                                 {
//                                                     question && question?.map((elem) => {
//                                                         return (
//                                                             <option value={elem?.id}>{elem?.question_name}</option>
//                                                         )
//                                                     })
//                                                 }
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-group">
//                                             <label>Subject</label>
//                                             <select className="form-control form-select"
//                                                 name='subject_id' value={x.subject_id}   onChange={(e) => handleSelfStudyDataChange(i, 'subject_id', e.target.value)}>
//                                                 <option value="">Choose Subject</option>
//                                                 {
//                                                     subjects && subjects?.map((elem) => {
//                                                         return <option value={elem?.id}>{elem?.subject}</option>
//                                                     })
//                                                 }
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-group">
//                                             <label>Upload Document</label>
//                                             <input type="file" className="form-control"
//                                                 name='u_document' onChange={(e) => handleSelfStudyImgChange(e, i)}
//                                                 accept=".pdf" />

//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-group">
//                                             <label>Embedded Link</label>
//                                             <input type="url" className="form-control"
//                                                 placeholder='Enter Link'
//                                                 value={x.link}
//                                                 name='link'
//                                                 onChange={(e) => handleSelfStudyDataChange(i, 'link', e.target.value)} />

//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-group">
//                                             <label>Upload Image</label>
//                                             <input type="file" className="form-control"
//                                                 name='u_image'
//                                                 onChange={(e) => handleSelfStudyImgChange(e, i)} />

//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="form-group">
//                                             <label>Upload Audio</label>
//                                             <input type="file" className="form-control"
//                                                 name='u_audio'
//                                                 onChange={(e) => handleSelfStudyImgChange(e, i)}
//                                                 accept='audio/*' />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <hr />
//                         </div>
//                     )
//                 }):
//                 <AddSelfStudy setSelfStudy={setSelfStudy} selfStudy={selfStudy} />
//             }
//         </>
//     )

// }
// export default EditSelfStudy
// unit_id: '',
// q_id: '',,
// u_document: '',
// link: '',
// u_image: '',
// u_audio: ''


import React, { useEffect, useState } from 'react';
import { get_question_by_type } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { get_text_books } from '../../../../apis/TextBook.jsx';

const EditSelfStudy = ({ subjectId, setSelfStudy, selfStudy, selectedSubjectId,singleTypeFormdata ,gradeId }) => {
  const [forms, setForms] = useState([]);
  const [question, setQuestion] = useState([]);
  const [existingSubject, setExistingSubject] = useState({});


    


  useEffect(() => {
    if((selectedSubjectId) && singleTypeFormdata ){
        console.log("singleTypeFormdata", singleTypeFormdata[`subject_id_${selectedSubjectId}`])
            setForms(singleTypeFormdata[`subject_id_${selectedSubjectId}`]);
  
      } 
  }, [selectedSubjectId , singleTypeFormdata]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
            questionnaire_type: 'Course',
            grade_id: gradeId,
        };
        const questionList = await get_question_by_type(payload);
        setQuestion(questionList?.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedSubjectId, setSelfStudy]);

  const handleSelfStudyInputChange = (e, i) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[i][name] = value;
    setForms(updatedForms);
    setSelfStudy(updatedForms);
  };

  const handleSelfStudyImgChange = (e, index) => {
    const { name, files } = e.target;
    const updatedForms = [...forms];
    updatedForms[index][name] = files[0] || null; // Handle empty file input
    setForms(updatedForms);
    setSelfStudy(updatedForms);
  };


    return (
        <div className="accordion" id="accordionExample">
            {
                forms?.map((x, i) => (
                    <div key={i} className="accordion-item">
                        <h2 className="accordion-header" id={`heading-${i}`}>
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse-${i}`}
                                aria-expanded={i === 0 ? true : false}
                                aria-controls={`collapse-${i}`}
                            >
                                Unit {i + 1}
                            </button>
                        </h2>
                        <div
                            id={`collapse-${i}`}
                            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`}
                            aria-labelledby={`heading-${i}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="modal-body">
                                <div className="row">
                                    <input type="hidden" name="unit_id" value={x.unit_id} />
                                    <div className="col-md-6" key={i}>
                                        <div className="form-group">
                                            <label>Questionaire {i + 1}</label>
                                            <select className="form-control form-select"
                                                name='q_id' value={x.q_id} onChange={(e) => handleSelfStudyInputChange(e, i)}>
                                                <option value="">Choose Question</option>
                                                {
                                                    question && question?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.question_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Document</label>
                                            <input type="file" className="form-control"
                                                name='u_document' onChange={(e) => handleSelfStudyImgChange(e, i)}
                                                accept=".pdf" />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Embedded Video Link</label>
                                            <input type="url" className="form-control"
                                                placeholder='Enter Link'
                                                value={x.link}
                                                name='link'
                                                onChange={(e) => handleSelfStudyInputChange(e, i)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Image</label>
                                            <input type="file" className="form-control"
                                                name='u_image'
                                                onChange={(e) => handleSelfStudyImgChange(e, i)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Audio</label>
                                            <input type="file" className="form-control"
                                                name='u_audio'
                                                onChange={(e) => handleSelfStudyImgChange(e, i)}
                                                accept='audio/*' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

export default EditSelfStudy;