import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { discout_update, discout_edit, discout_add } from '../../../../apis/Discount';
import { Button } from 'semantic-ui-react';
import { handleNavigation } from '../../../../Utils/Utils';
const AddDisount = () => {
    const {
        register,
        handleSubmit,
        formState: { errors,isDirty }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);

        data.fees=''
        let result = await discout_add(data);
        if (result.status) {
            return navigate('/admin/general-setting/discount/all');
        }
        setIsSubmitting(false);
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Discount</h4>
                    <Link className='edit-btn' to={`/admin/general-setting/discount/all`} onClick={(e) => {handleNavigation(e,isDirty)}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Discount Name *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Discount Name'
                                        {...register('name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Discount % *</label>
                                    <input type="text" className="form-control"
                                     onKeyPress={(event) => {
                                        if (!/^[0-9\.]$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    maxlength="10"
                                        placeholder='Enter Discount %'
                                        {...register('percentage', { required: true })}
                                    />
                                </div>
                                {errors.percentage && errors.percentage.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>TAX Fee</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Tax Fee'
                                        {...register('fees', { required: true })}
                                    />
                                </div>
                                {errors.fees && errors.fees.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                           <div className="col-md-6">
    <div className="form-group">
        <label>Status</label>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio1"
                value="1"
                defaultChecked
            />
            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
        </div>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio2"
                value="0"
            />
            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
        </div>
    </div>
    {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
</div>

                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const EditDiscount = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors,isDirty }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const id = useParams();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);

        data.fees=''
        let result = await discout_update(id, data);
        if (result.status) {
            return navigate('/admin/general-setting/discount/all');
        }
        setIsSubmitting(false);
    }
    const fetchData = async () => {
        let res = await discout_edit(id);
        setValue('name', res?.data[0]?.name);
        setValue('fees', res?.data[0]?.fees);
        setValue('status', res?.data[0]?.status?.toString());
        setValue('percentage', res?.data[0]?.percentage);
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Discount </h4>
                    <Link className='edit-btn' to={`/admin/general-setting/discount/all`} onClick={(e) => {handleNavigation(e,isDirty)}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Discount Name *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Discount Name'
                                        {...register('name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Discount % *</label>
                                    <input type="text" className="form-control"
                                    onKeyPress={(event) => {
                                        if (!/^[0-9\.]$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    maxlength="10"
                                        placeholder='Enter Discount %'
                                        {...register('percentage', { required: true })}
                                    />
                                </div>
                                {errors.percentage && errors.percentage.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>TAX Fee</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Tax Fee'
                                        {...register('fees', { required: true })}
                                    />
                                </div>
                                {errors.fees && errors.fees.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>status</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio1" value={1} />
                                        <label class="form-check-label" for="inlineRadio1">Active</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio2" value={0} />
                                        <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                    </div>
                                </div>
                                {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export { AddDisount, EditDiscount };
