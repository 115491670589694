import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { get_website_data } from '../../../../apis/generalSetting/WebsiteEnv'
const Websit_env_view = () => {
    const [envView, setEnvView] = useState([]);
    const id = useParams()
    useEffect(() => {
        async function get_data() {
            const envView = await get_website_data(id);
            const data = envView?.data
            setEnvView(data);
        }
        get_data();
    }, []);
    function formatDate(dateString) {
        if (!dateString) return '-';
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${day}-${month}-${year}`;
    }
    return (
        <div>
            <h4 className="modal-title">Website Env</h4>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Website Env Detail</h4>
                        <div className='modify-right'>
                            <Link
                                className="edit-btn"
                                to={`/admin/administrator-setting/websiteEnv/all`}
                            >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="form-horizontal inner-item-env">
                                    <div className="row">
                                        {
                                            envView?.term?.map((ele) => {
                                                return (
                                                    <div className="col-md-3">
                                                        <div className='term-periods-sec-n'>
                                                            <div className="form-group">
                                                                <label>Term - {ele?.name}</label>

                                                            </div>
                                                            <div className="form-group">
                                                                <label>Max Week </label>
                                                                <p>{ele?.max_week}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Start Date </label>
                                                                <p>{formatDate(ele?.start_date)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='new-sec-tab-n'>
                                                <h3>Current Term Data</h3>
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <p>{envView?.state_name ? envView?.state_name : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Current Term</label>
                                                            <p>{envView?.Current_term ? envView?.Current_term : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Current Year</label>
                                                            <p>{envView?.Current_year ? envView?.Current_year : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Classtest Barnches</label>
                                                            <p>{envView?.Classtest_branches ? envView?.Classtest_branches : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Default Period 4 PA</label>
                                                            <p>{envView?.Default_period4PA ? envView?.Default_period4PA : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Sdate G</label>
                                                            <p>{envView?.SdateG ? formatDate(envView?.SdateG) : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Sdate M</label>
                                                            <p>{envView?.SdateM ? formatDate(envView?.SdateM) : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Sdate E</label>
                                                            <p>{envView?.SdateE ? formatDate(envView?.SdateE) : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Sdate W</label>
                                                            <p>{envView?.SdateW ? formatDate(envView?.SdateW) : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Test Period</label>
                                                            <p>{envView?.Test_period ? envView?.Test_period : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Holi Off Test Review Period</label>
                                                            <p>{envView?.Holi_off_test_review_Period ? envView?.Holi_off_test_review_Period : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Off Test Review Period</label>
                                                            <p>{envView?.Off_test_review_period ? envView?.Off_test_review_period : '-'}</p>
                                                        </div>
                                                    </div><div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Test Review Period</label>
                                                            <p>{envView?.Test_review_period ? envView?.r_period : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Review Date</label>
                                                            <p>{envView?.Review_date ? formatDate(envView?.Review_date) : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Unit Test Start Week</label>
                                                            <p>{envView?.Unit_test_start_week ? envView?.Unit_test_start_week : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Group Email Password</label>
                                                            <p>{envView?.group_email_password ? envView?.group_email_password : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Default Path For Wmsd</label>
                                                            <p>{envView?.default_path_for_wms ? envView?.default_path_for_wms : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Smtp Email Account</label>
                                                            <p>{envView?.smtp_email_account ? envView?.smtp_email_account : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Smtp Password</label>
                                                            <p>{envView?.smtp_password ? envView?.smtp_password : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Cur Au</label>
                                                            <p>{envView?.cur_au ? envView?.cur_au : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Cur Kr</label>
                                                            <p>{envView?.cur_kr ? envView?.cur_kr : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Cur Mark Au</label>
                                                            <p>{envView?.cur_mark_au ? envView?.cur_mark_au : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Cur Mark Kr</label>
                                                            <p>{envView?.cur_mark_kr ? envView?.cur_mark_kr : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Websit_env_view;
