import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { add_text_book } from "../../../apis/TextBook.jsx";
import { grade_all } from "../../../apis/Grade.jsx";
import { get_terms } from "../../../apis/Common.jsx";
import { subject_all } from "../../../apis/ClassManagement/Subjects.jsx";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../Utils/Utils.js";
const AddTextBook = () => {
  const [gradeList, setGradeList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [file, setFile] = useState(null);
  const [subList, setSubList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
    setFile(selectedFile);
  };
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const form = new FormData();
    form.append("image", file);
    form.append("name", getValues("name"));
    form.append("price", getValues("price"));
    form.append("status", getValues("status"));
    form.append("term_id", getValues("term_id"));
    form.append("grade_id", getValues("grade_id"));
    form.append("subject_id", getValues("subject_id"));
    let result = await add_text_book(form);
    if (result.status) {
      return navigate("/admin/course-managment/text-book/all");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const gradeList = await grade_all();
      const termList = await get_terms();
      const subjectList = await subject_all();
      setGradeList(gradeList?.data);
      setTermList(termList?.data);
      setSubList(subjectList?.data);
    };
    fetchData();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Text Books</h4>
          <Link
            className="edit-btn"
            to={`/admin/course-managment/text-book/all`}
            onClick={(e) => {handleNavigation(e,isDirty)}}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Grade *</label>
                  <select
                    className="form-select form-control"
                    {...register("grade_id", { required: true })}
                  >
                    <option value="">Choose Grade</option>
                    {gradeList?.length > 0 ? (
                      gradeList &&
                      gradeList?.map((elem) => {
                        return (
                          <option
                            value={elem?.id}
                          >{`YEAR ${elem?.name}`}</option>
                        );
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                  {errors.grade_id && errors.grade_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Term *</label>
                  <select
                    className="form-select form-control"
                    name="term"
                    {...register("term_id", { required: true })}
                  >
                    <option value="">Choose Term </option>
                    {termList &&
                      termList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })}
                  </select>
                  {errors.term_id && errors.term_id.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Subject *</label>
                  <select
                    className="form-control form-select"
                    {...register("subject_id", { required: true })}
                  >
                    <option value="">Choose Subject</option>
                    {subList &&
                      subList?.map((elem) => {
                        return (
                          <option value={elem?.id}>{elem?.subject}</option>
                        );
                      })}
                  </select>
                  {errors.subject_id &&
                    errors.subject_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Text Book Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Text Book Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Price *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Price"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register("price", { required: true })}
                  />
                  {errors.price && errors.price.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Link</label>
                                    <input type="url" className="form-control"
                                        placeholder='Enter Link'
                                        {...register('link', { required: true })}
                                    />
                                </div>
                                {errors.link && errors.link.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Text Book Image</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="ENTER file"
                    {...register("image")}
                    onChange={handleChange}
                  />
                </div>
                {/* {errors.image && errors.image.type === "required" && <span className="validation-error">This is required</span>} */}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>status</label>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                  {errors.status && errors.status.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              {/* <button type='button' className="btn btn-primary">Savffde</button> */}
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTextBook;
