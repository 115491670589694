import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { get_roles } from "../../../../apis/generalSetting/RolePermission";
import AddRolePermission from "./Add";
import EditRolePermission from "./Edit";


const AllRolePermission = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [isModalOpen,setIsModalOpen]=useState(false);
  const [editModal,setEditModal]=useState(false);
  useEffect(() => {
    async function get_data() {
      const result = await get_roles();
      setData(result?.data);
    }
    get_data();
  }, [editModal,isModalOpen]);
  console.log('isModalOpenisModalOpen',data);
  
  return (
    <>
    <div className="right-nav" id="dash-event">
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Role and Permission</h4>
        </div>
        <div className="col-md-8">
          <button
            className="ui button float-end"
            onClick={()=>{setIsModalOpen(true)}}
          >
            New role
          </button>
        </div>
      </div>
      <div className="accunts-global-sec">
        <div className="container">
          <div className="accountsblobal-inner">
            {
              data && data?.map((ele)=>{
               return(
                <a
                className="btn btn-primary account-btn"
                onClick={()=>{setEditModal(true);setId(ele?.role_id)}}
                role="button"
              >
                <div className="accoundbtn-cnts">
                  <div className="accnt-top-cnts">
                    <div className="btn-heading">{ele?.role}</div>
                    <div className="blobal-txt">{ele?.branch}</div>
                  </div>
                  <p>
                  {ele?.description}
                  </p>
                </div>
                
 
                        {ele?.first_names?.map((firstName, index) => (
                          <div key={index} className="profiles_s-img prf_margin">
                            <img src="images/static-profile-image.jfif" alt="profile image" />
                            <div className="blobal-txt">{firstName}</div>
                          </div>
                        ))}


                        <div className="profiles_s-img profile_s-txt prf_margin">
                          {ele?.total_count}
                        </div>
                     
              </a>
               ) 
              })
            }
          </div>
        </div>
      </div>
      </div>
      <AddRolePermission isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <EditRolePermission isModalOpen={editModal} setIsModalOpen={setEditModal} id={id} />
    </>
    
  );
};
export default AllRolePermission;
