import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

export async function addWebsiteEnv(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-website-env', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
///get-website-data

// export async function get_website_data(id) {
//     try {
//         const response = await fetch(HTTPURL + 'api/get-website-data);
//         const responseData = await response.json();
//         if (responseData.status) {
//             // toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }

export async function get_website_data(id) {
    try {
        const response = await fetch(HTTPURL + `api/get-website-data?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status === true) {
            // toast.success(responseData?.message);
        } else if (responseData?.status === false) {
            toast.success(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_all_website_env_data(id) {
    try {
        const response = await fetch(HTTPURL + `api/all-web-data?current_year=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function updateWebsiteEnv(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/update-website-env/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_all_year_data() {
    try {
        const response = await fetch(HTTPURL + 'api/total-year');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function dublicate_curr_term_data(id) {
    try {
        const response = await fetch(HTTPURL + `api/duplicate-curr-term-data?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}