import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import CreateRoute from './routes/Route';
import TeacherRoute from './teachers/routes/Route';
import BranchRoute from './branches/routes/Route'
import Modal from 'react-modal';
Modal.setAppElement('#root');
function App() {
  return (
    <>
      <CreateRoute />
      <TeacherRoute />  
      <BranchRoute />
    </>
  );
}

export default App;
