import React, { useState, useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import CustomTable from '../../CustomTable';
import { get_question, question_destroy, question_edit } from '../../../../apis/testManagement/Question'
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Questionnaire Name',
        selector: 'question_name',
        sortable: true,
    },
    {
        name: 'Duration',
        selector: 'duration',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllQuestionaire = () => {
    const [grade, setGrade] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        async function get_data() {
            const result = await get_question();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, time, test_type, question_name,name } = elem;
                const questionID = result?.data?.id;
                console.log('questionID',questionID );
                return {
                    no: `#${index + 1}`,
                    grade: name==0?`year ${name}(kindy)`:`year ${name}`,
                    question_name: question_name,
                    duration: `${time} minutes`,
                    test_type: test_type,
                    action: <div data-tag="allowRowEvents">
                        <Link className='edit-btn' onClick={() => navigateToEditPage(id)}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                         {/* <button className="edit-btn" onClick={() => navigateToEditPage(id)}>   <i class="fa fa-pencil" aria-hidden="true"></i></button> */}
                        <Link className='edit-btn' to={`/admin/test-management/questionaire-allDetail/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id)}>    <i class="fa fa-trash" aria-hidden="true"></i></button></div>
                };
            });
            setGrade(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                question_destroy(id);
            }
            get_data();
        }
        const navigateToEditPage = async(id)=>{
            const result = await question_edit(id);
            let data = result.data
            localStorage.setItem("questionData", JSON.stringify(data))
            console.log("result" , data)

            navigate(`/admin/test-management/questionaire-add/add/${data.grade_id}/${data.subject_id}`)


        }
        get_data();
    }, []);

    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="right-nav" id="dash-event">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <h4 className='modal-title'>Questionnaire List</h4>
                                </div>
                                <div className='col-md-8'>
                                    <Link className='ui button float-end' to="/admin/test-management/questionaire-add">Add Questionnaire
                                    </Link>
                                    {/* <Link className='ui button float-end' to="/admin/test-management/questionaire-allDetail">View
                                    </Link> */}
                                </div>
                            </div>
                            {
                                showResults ?
                                    <CustomTable
                                        columns={columns}
                                        data={grade}
                                    />
                                    :
                                    <DataLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AllQuestionaire;



