import React from "react";
import { toast } from "react-toastify";
import { Form, Button } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { grade_all } from "../../../../apis/Grade";
import { get_category } from "../../../../apis/category";
import { get_terms } from "../../../../apis/Common.jsx";
import { subject_all } from "../../../../apis/ClassManagement/Subjects.jsx";
// import QuestionaireAdd from './AddQuestionaire.jsx';
export const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Test Type",
    selector: "test_type",
    sortable: true,
  },
  {
    name: "Question",
    selector: "question",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
];
const AddQuestionaire = () => {
  const [showQuestionaire, setShowQuestionaire] = useState(false);
  const [localData, setLocalData] = useState({
    time: "",
    question_name: "",
    grade: "",
    term: "",
    course_type: "",
    subject: "",
    term_id: "",
    course_type_id: "",
  });
  const [selectVal, setSelectVal] = useState({
    time: "",
    question_name: "",
    grade: "",
    term: "",
    course_type: "",
    subject: "",
    category_id: "",
    sub_category_id: "",
    questionnaire_type: "",
  });
  const [subjects, setSubject] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [termsList, setTermList] = useState([]);
  const [handleNavigation, setHandleNavigation] = useState(false);
  const navigate = useNavigate();

  const proceed = () => {
    if (
      selectVal.grade != "" &&
      selectVal.time != "" &&
      selectVal.question_name != "" &&
      selectVal.subject != "" &&
      selectVal.questionnaire_type != ""
    ) {
      // get_data();
      const url = `/admin/test-management/questionaire-add/add/${selectVal.grade}/${selectVal.subject}`;
      localStorage.setItem("questionData", JSON.stringify(localData));
      // window.open(url,'_blank')
      navigate(url);
      setShowQuestionaire(true);
    } else {
      toast.error("All fieds are required");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const result = await subject_all();
      const gradeList = await grade_all();
      const courseType = await get_category();
      const terms = await get_terms();
      setGradeList(gradeList?.data);
      setCourseType(courseType?.data);
      setTermList(terms?.data);
      setSubject(result?.data);
    };
    // get_data();
    fetchData();
  }, []);
  useEffect(() => {
    const array = Object.values(selectVal);
    const hasNonEmptyElement = array.some((item) => item.length > 0);
    console.log("woking ", hasNonEmptyElement);
    if (hasNonEmptyElement === true) {
      setHandleNavigation(true);
    }
  }, [selectVal]);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Questionnaire</h4>
          <Link
            className="edit-btn"
            to={`/admin/test-management/questionaire-all`}
            onClick={(e) => {
                if(handleNavigation === true){
                    if (window.confirm("Are you sure you want to leave this page?")) {
                    } else {
                      e.preventDefault();
                    }
                }
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          {/* {
                        showQuestionaire ?
                            // <QuestionaireAdd selectVal={selectVal} setSelectVal={setSelectVal} />
                            : */}

          <Form className="form-horizontal" encType="multipart/form-data">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Course Type </label>
                  <select
                    className="form-select form-control"
                    onChange={(e) => {
                      setSelectVal({
                        ...selectVal,
                        course_type: e.target.value,
                      });
                      setLocalData({
                        ...localData,
                        course_type: e.target.value
                          ? e.target.selectedOptions[0].text
                          : "",
                        course_type_id: e.target.value ? e.target.value : "",
                      });
                    }}
                  >
                    <option value="">Choose Course Type</option>
                    {courseType &&
                      courseType?.map((elem) => {
                        return (
                          <option value={elem?.id}>
                            {elem?.category_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Term </label>
                  <select
                    className="form-select form-control"
                    name="term"
                    onChange={(e) => {
                      setSelectVal({ ...selectVal, term: e.target.value });
                      setLocalData({
                        ...localData,
                        term: e.target.value
                          ? e.target.selectedOptions[0].text
                          : "",
                        term_id: e.target.value ? e.target.value : "",
                      });
                    }}
                  >
                    <option value="">Choose Term </option>
                    {termsList &&
                      termsList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Grade *</label>
                  <select
                    className="form-select form-control"
                    onChange={(e) => {
                      setSelectVal({ ...selectVal, grade: e.target.value });
                      setLocalData({
                        ...localData,
                        grade: e.target.selectedOptions[0].text,
                      });
                    }}
                  >
                    <option value="">Choose Grade </option>
                    {gradelist?.length > 0 ? (
                      gradelist.map((elem) => {
                        return (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        );
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Time (min)*</label>
                  {/* <select className="form-select form-control"  onChange={(e) => setSelectVal({ ...selectVal, time:e.target.value })}>
                                        <option value="">CHOOSE TIME</option>
                                        <option value="5">5 mint</option>
                                        <option value="10">10 mint</option>
                                        <option value="15">15 mint</option>
                                        <option value="20">20 mint</option>
                                        
                                    </select> */}
                  <input
                    type="text"
                    className="form-control"
                    name="time"
                    placeholder="Enter Time"
                    maxLength={2}
                    onChange={(e) => {
                      setSelectVal({ ...selectVal, time: e.target.value });
                      setLocalData({ ...localData, time: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Questionnaire Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="question"
                    placeholder="Enter Questionnaire"
                    onChange={(e) => {
                      setSelectVal({
                        ...selectVal,
                        question_name: e.target.value,
                      });
                      setLocalData({
                        ...localData,
                        question_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Subject *</label>
                  <select
                    className="form-control form-select"
                    onChange={(e) => {
                      setSelectVal({ ...selectVal, subject: e.target.value });
                      setLocalData({
                        ...localData,
                        subject: e.target.selectedOptions[0].text,
                      });
                    }}
                    required
                  >
                    <option value="">Choose Subject </option>
                    {subjects &&
                      subjects?.map((elem) => {
                        return (
                          <option value={elem?.id}>{elem?.subject}</option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Questionnaire Type *</label>
                  <select
                    className="form-control form-select"
                    onChange={(e) => {
                      setSelectVal({
                        ...selectVal,
                        questionnaire_type: e.target.value,
                      });
                      setLocalData({
                        ...localData,
                        questionnaire_type: e.target.selectedOptions[0].text,
                      });
                    }}
                    required
                  >
                    <option value="">Choose Questionnaire type </option>
                    <option value="course">Course</option>
                    <option value="FreeAssessmentTest">
                      Free Assessment Test
                    </option>
                    <option value="SelectiveTrialTest">
                      Selective Trial Test
                    </option>
                  </select>
                </div>
              </div>
              <span
                onClick={() => proceed()}
                className="btn btn-primary questionair-btn"
              >
                Proceed
              </span>
            </div>
          </Form>
          {/* } */}
        </div>
      </div>
    </div>
  );
};
export default AddQuestionaire;
