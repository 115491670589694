import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { branch_all } from "../../../../apis/Branches";
import {
  get_permissions,
  addRole,
  get_roles
} from "../../../../apis/generalSetting/RolePermission";
import { useEffect, useState } from "react";
const AddRolePermission = ({ isModalOpen, setIsModalOpen}) => {
  const [branchlist, setBranchList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionsState, setPermissionsState] = useState(
    permissions.reduce((acc, module) => {
      acc[module.module_id] = module.permission.map((perm) => ({
        permission_id: perm.id,
        permission_granted: 0,
      }));
      return acc;
    }, {})
  );
  
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    const fetchData = async () => {
      const res = await branch_all();
      const permission = await get_permissions();
      setBranchList(res?.data);
      setPermissions(permission?.data?.modules);
    };
    fetchData();
  }, []);
  console.log("permissions", permissions);
  const togglePermission = (moduleId, permId) => {
    setPermissionsState((prevState) => {
      const updatedState = { ...prevState };
  
      if (!updatedState[moduleId]) {
        updatedState[moduleId] = [];
      }
  
      const permissionIndex = updatedState[moduleId].findIndex(
        (perm) => perm.permission_id === permId
      );
  
      if (permissionIndex === -1) {
        updatedState[moduleId].push({
          permission_id: permId,
          permission_granted: 1,
        });
      } else {
        updatedState[moduleId][permissionIndex].permission_granted =
          updatedState[moduleId][permissionIndex].permission_granted === 1 ? 0 : 1;
      }
  
      return updatedState;
    });
  };
  
  const toggleAllPermissions = (moduleId, isChecked) => {
    setPermissionsState((prevState) => {
      const updatedState = { ...prevState };
      if (!updatedState[moduleId]) {
        updatedState[moduleId] = [];
      }
      const modulePermissions = permissions.find(
        (module) => module.module_id === moduleId
      )?.permission;
      if (modulePermissions) {
        modulePermissions.forEach((perm) => {
          const permissionIndex = updatedState[moduleId].findIndex(
            (p) => p.permission_id === perm.id
          );
          if (permissionIndex === -1) {
            updatedState[moduleId].push({
              permission_id: perm.id,
              permission_granted: isChecked ? 1 : 0,
            });
          } else {
            updatedState[moduleId][permissionIndex].permission_granted = isChecked ? 1 : 0;
          }
        });
      }
      return updatedState;
    });
  };
  
  const onSubmit = async (data) => {
    const permissionsPayload = [];
    Object.values(permissionsState).forEach((permissions) => {
      permissions.forEach((permission) => {
        permissionsPayload.push({
          permission_id: permission.permission_id,
          status: permission.permission_granted,
        });
      });
    });
    console.log('permissionsPayload',permissionsPayload);
    
// return
    const formData = {
      role: data.role,
      description: data.description,
      branch_id: data.branch_id,
      permissions: permissionsPayload,
    };
    let result = await addRole(formData);
    if (result.status) {
      setIsModalOpen(false);
      get_roles();
      // return navigate("/admin/administrator-setting/websiteEnv/all");
    }
    console.log("Form Data to Submit:", formData);
  };
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        contentLabel="Edit Grade"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">Add role</h1>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mt-3">
              <label className="label-txt">Role</label>
              <input
                type="text"
                placeholder="Manager"
                className="form-control"
                id="inputtext_1"
                {...register("role", { required: "true" })}
              />
              {errors.role && errors.role.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Branch list</label>
              <select
                className="form-select"
                {...register("branch_id", { required: "true" })}
              >
                <option value="">Choose Branch</option>
                {branchlist?.length > 0 ? (
                  branchlist &&
                  branchlist?.map((elem) => {
                    return (
                      <option value={elem?.id}>{elem?.branch_name}</option>
                    );
                  })
                ) : (
                  <option>No result</option>
                )}
              </select>
              {errors.branch_id && errors.branch_id.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Description</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="4"
                placeholder="Enter your message here..."
                {...register("description", { required: "true" })}
              ></textarea>
              {errors.description && errors.description.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
          </div>

          <div className="accordionss">
            {permissions?.map((module) => (
              <div className="accordion-item" key={module.module_id}>
                <h2
                  className="accordion-header"
                  id={`heading${module.module_id}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${module.module_id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${module.module_id}`}
                  >
                    <div className="accrd-heading">{module.module_name}</div>
                    <div className="checkbox-onoff">
                      <input
                        type="checkbox"
                        id={`toggle${module.module_id}`}
                        checked={permissionsState[module.module_id]?.every(
                          (perm) => perm.permission_granted
                        )}
                        onChange={(e) =>
                          toggleAllPermissions(
                            module.module_id,
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="switch"
                        htmlFor={`toggle${module.module_id}`}
                      ></label>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${module.module_id}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`heading${module.module_id}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {module.permission.map((perm) => (
                      <div className="accodion-body-inn" key={perm.id}>
                        <div className="accrd-heading">
                          {perm.permission_type}
                        </div>
                        <div className="checkbox-onoff">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`permission_${perm.id}`}
                            checked={
                              permissionsState[module.module_id]?.find(
                                (p) => p.permission_id === perm.id
                              )?.permission_granted || false
                            }
                            onChange={() =>
                              togglePermission(module.module_id, perm.id)
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`permission_${perm.id}`}
                          ></label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="deo-buttons_s">
            <button type="submit" className="popup-btn">
              {" "}
              Delete role
            </button>
            <button type="submit" className="popup-btn">
              {" "}
              Save Changes
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default AddRolePermission;
