import React from "react";
import { Button } from 'semantic-ui-react';

import Logo from '../../../image/cslogo-300x34.png';

const Home = () => {
    return (
        <>
            <div id="layoutAuthentication" className="login-page" style={{ marginTop: '0px' }}>
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg welcome-card">
                                        <div className="card-header">
                                            <div className="login-head-logo">
                                                <img src={Logo} alt="CSLogo_white.png" />
                                            </div>
                                            {/* <div className="text-center font-weight-light">only for adminstrator</div> */}
                                        </div>
                                        <div className="card-body">
                                            <h2 style={{ textAlign: 'center', marginBottom: '50px', color: '#fff' }}>
                                                Choose Your Role
                                            </h2>
                                            <div className="d-flex justify-content-center welcome-sec">
                                                <a 
                                                    href="/admin"
                                            
                                                >
                                                    <Button secondary size="large" style={{ height: '63px', color: '#fff', fontSize: "18px !important" }}>
                                                        Admin
                                                    </Button>
                                                </a>
                                                <a 
                                                    href="/branch"
                                                   
                                                >
                                                    <Button secondary size="large" style={{ height: '63px', color: '#fff', fontSize: "18px !important" }}>
                                                        Branch
                                                    </Button>
                                                </a>
                                                <a 
                                                    href="/teacher"
                                                    
                                                >
                                                    <Button secondary size="large" style={{ height: '63px', color: '#fff', fontSize: "18px !important" }}>
                                                        Teacher
                                                    </Button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Home;
