import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add grade
export async function grade_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/grade-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all grades
export async function grade_all() {
    try {
        const response = await fetch(HTTPURL + 'api/grades-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all grades
export async function grades_all_data() {
    try {
        const response = await fetch(HTTPURL + 'api/all-grades-data');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// grade edit 
export async function grade_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/grade-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// grade update 
export async function grade_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/grade-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function grade_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/grade-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
