import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {  useNavigate, useParams } from 'react-router-dom';
import { Selective_trial_test_update, Selective_trial_test_edit } from '../../../../apis/Selective_trial_test.jsx'
import { get_question_by_type } from '../../../../apis/testManagement/Question.jsx';
import { grade_all } from '../../../../apis/Grade';
import { Button } from 'semantic-ui-react';
const EditTrialTest = ({ isModalOpen, setIsModalOpen, duplicateItemId, setStatus }) => {
    const [question, setQuestion] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [gradeID, setGradeID] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({mode:'onChange'});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const id = useParams();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        let result = await Selective_trial_test_update(duplicateItemId, data);
        if (result?.status) {
            { setIsModalOpen(false); setStatus("") }
            window.location.reload();
            getData();
            return navigate('/admin/course-managment/selective-trial-test/all');
        }
        setIsSubmitting(false);

    }
    // if (isModalOpen) {
    //     //grade get data api
     
    //     get_data();
    // }
    async function fetch_data() {
        const result = await Selective_trial_test_edit(duplicateItemId);
        if (result?.data?.length > 0) {
            const { status, question_id,grade_id} = result?.data[0];
            setValue('status', status);
            setValue('question_id', question_id);
            setValue('grade_id',grade_id)
            setGradeID(grade_id)
        }
    }
    async function getData() {
        const payload={
            questionnaire_type:'Selective Trial Test',
            grade_id:gradeID
        }
        if(gradeID){
            const question = await get_question_by_type(payload);
            setQuestion(question?.data)
        }
        const gradeList = await grade_all();
        setGradeList(gradeList?.data);
    }
    useEffect(() => { 
        fetch_data();
        getData();
        return (
            setIsModalOpen(false)
        )
    }, [isModalOpen,gradeID]);
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Edit Selective Trial Test</h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                <div className='row'>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label>Grade *</label>
                                    <select className="form-select form-control" {...register('grade_id', { required: true })} onChange={(e)=>setGradeID(e.target.value)} >
                                        <option value="">Choose Grade</option>
                                        {
                                            gradelist?.length > 0 ?
                                                gradelist && gradelist?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.name === 0 ? `Year ${elem.name} (kindy)` : `Year ${elem.name}`}</option>
                                                    )
                                                })
                                                :
                                                <option>No result</option>
                                        }
                                    </select>
                                </div>
                                {!gradeID && errors.grade_id && errors.grade_id.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Questionaire *</label>
                                            <select className="form-control form-select" {...register("question_id",{ required: true })}>
                                                <option value="">Choose Question</option>
                                                {
                                                    question && question?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.question_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.question_id && errors.question_id.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default EditTrialTest;

