import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import CustomTable from '../CustomTable';
import { member_destroy, members_all } from '../../../apis/studentManagement/StudentManagement';
import { branch_all } from '../../../apis/Branches';
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    // {
    //     name: 'Student Id',
    //     selector: 'id',
    //     sortable: true,
    // },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Branch',
        selector: 'branch',
        sortable: true,
    },
    {
        name: 'Course',
        selector: 'course_name',
        sortable: true,
    },
    // {
    //     name: 'Contact',
    //     selector: 'contact',
    //     sortable: true,
    // },
    {
        name: 'Sign In Date',
        selector: 'created_at',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'delete_status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const Members = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [branchlist, setBranchList] = useState([]);
    const [id, setId] = useState('');
    useEffect(() => {
        async function get_data() {
            const result = await members_all(id);
            const branchList = await branch_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, s_id, first_name, last_name, created_at, branch_name, name, student_number, school, student_type,course_name,delete_status } = elem;
                const date = new Date(created_at);
                const month = (date?.getMonth() + 1).toString().padStart(2, '0');
                const day = date?.getDate().toString().padStart(2, '0');
                const year = date?.getFullYear();
                const formattedDate = `${day}-${month}-${year}`;
                const lname = last_name || last_name == 'Undefined' ? last_name : '';
                const courseNames = elem.course_name.split(',');
                let primaryCourseName ;
                if(courseNames.length > 1){
                     primaryCourseName = `${courseNames[0]}...`; 
                }else{
                    primaryCourseName = courseNames[0];
                }
               // const primaryCourseName = courseNames[0]; 
                const secondaryCourseNames = courseNames.slice(1); 
                
                return {
                    no: `#${index + 1}`,
                    id: `CSPRO00${id}`,
                    name: first_name?.toLowerCase() + ' ' + lname?.toLowerCase(),
                    grade: name === 0 ? `Year ${name} (kindy)` : `Year ${name}`,
                    branch: branch_name ? branch_name:'-',
                    school: school,
                    contact: student_number || '-',
                    created_at: formattedDate?.slice(0, 10),
                    student_type: student_type == '1' ? 'Enrolled' : 'free',
                    // course_name: course_name ? (
                    //       <span>
                    //         {primaryCourseName}
                    //         {secondaryCourseNames.length > 0 && (
                    //           <span className="tooltip">
                    //             <i className="fa fa-info-circle" aria-hidden="true" />
                    //             <span className="tooltiptext">
                    //               {secondaryCourseNames.join(', ')}
                    //             </span>
                    //           </span>
                    //         )}
                    //       </span>
                    //     ) : '-',
                    course_name: course_name ? (
                        <div  data-toggle="tooltip" data-placement="top" title={secondaryCourseNames.join(', ')}>
                            {primaryCourseName}
                        </div>
                    ): '-',
                    delete_status: delete_status == '1' ? 'Inactive' : 'Active',
                    action: <div data-tag="allowRowEvents">
                        <Link className='edit-btn' to={`/admin/students/student_enroll_view/${s_id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        {/* {student_type == '1' ? <Link className='edit-btn' to={`/admin/students/student_enroll_view/${s_id}`}>View</Link> : ''} */}
                        <Link className='edit-btn' to={`/admin/students/member-edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id,delete_status === 1 ?0:1)}>{delete_status === 1 ? <i class="fa-solid fa-check"></i>:  <i class="fa fa-trash" aria-hidden="true"></i>} </button>
                    </div>,
                };
            });
            setData(arrayData, setShowResults(true));
            setBranchList(branchList?.data);
        }
        const destroy =async (id,data) => {
            console.log('data',data)
            const dataa={
                delete_status:data
            }
            const result = window.confirm(`${data === 1 ? 'Are you sure you want to inactive this user ?' :'Are you sure you want to active this user ? '}`);
            if (result) {
             const res= await  member_destroy(id,dataa);
             console.log('res',res)
             if(res?.data){
                get_data(); 
             }
            }
            get_data();
        }
        get_data();
    }, [id]);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Student List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/students/add-member">Add Student</Link>
                </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <label>Branch</label>
                    <select className="form-select form-control " name="branch" onChange={(e) => setId(e.target.value)} >
                        <option value="">CHOOSE BRANCH</option>
                        {
                            branchlist?.length > 0 ?
                                branchlist && branchlist?.map((elem) => {
                                    return (
                                        <option value={elem?.id}>{elem?.branch_name}</option>
                                    )
                                })
                                :
                                <option>No result</option>
                        }
                    </select>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default Members;
