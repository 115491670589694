import React, { useEffect, useState } from 'react';
import { columns } from './Data.js';
import { Link } from 'react-router-dom';
import { events_all, events_destroy } from '../../../apis/Events.jsx';
import DataLoader from '../../../Utils/DataLoader.jsx';
import CustomTable from '../CustomTable.jsx';

const Events = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false)
  useEffect(() => {
    async function get_data() {
      const result = await events_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, event_type, date, title, venue_name,offline_online_event } = elem;
        const formattedDate = date ? date.split('-').reverse().join('-') : ''; 
        return {
          no: `#${index + 1}`,
          type: event_type,
          date:formattedDate,
          title,
          venue: venue_name,
          offline_online_event,
          action: <div data-tag="allowRowEvents">
            <Link className='edit-btn' to={`/admin/administrator-setting/events/EventView/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
            <Link class="edit-btn" to={`/admin/administrator-setting/events/edit/${id}`}>
            <i class="fa fa-pencil" aria-hidden="true"></i></Link>
            <button className="dlt-btn" onClick={() => destroy(id)}><i class="fa fa-trash" aria-hidden="true" ></i></button></div>
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
      const result = window.confirm("Are you sure you want to delete?");
      if (result) {
        events_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Event List</h4>
        </div>
        <div className='col-md-8'>
          <Link className='ui button float-end' to="/admin/administrator-setting/events/add">New Event</Link>
        </div>
      </div>
      {
        showResults ?
          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader />
      }
    </div>
  )
}
export default Events;
