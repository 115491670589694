import React, {useEffect, useState } from 'react';
import { get_question_by_type } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import {get_text_books, get_text_books_subject_id} from '../../../../apis/TextBook.jsx';
const AddLecture = ({ setLecture, lecture }) => {
    const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    const [textBook, setTextBook] = useState([]);
    // const [date,setDate]=useState('');
    //lecture handle change
    const handleLectureInputChange = (e, index) => {
        const { name, value } = e.target;
        if(name=='subject_id'){
            localStorage.setItem('courseSubjectID',value)
        }
        const list = [...lecture];
        list[index][name] = value;
        setLecture(list);
    };

    //lecture handle change for image
    const handleLectureImgChange = (e, index) => {
        const { name, files } = e.target;
        const list = [...lecture];
        list[index][name] = files[0];
        setLecture(list);
    }
    //lecture handle click remove
    const handleLectureRemoveClick = (index) => {
        const list = [...lecture];
        const remove = list.filter((_, indexFilter) => !(indexFilter === index));
        setLecture(remove);
    }
    //lecture handle click add button
    const handleLectureAddClick = () => {
        setLecture([...lecture, {
            unit: '',
            week: '',
            q_id: '',
            u_image: '',
            subject_id: '',
            u_audio: '',
            description: '',
            u_document: '',
            link: '',
            text_book_id:''
        }])
    }
    useEffect(() => {
        const fetchData = async () => {
            const questionList = await get_question_by_type('Course');
            const result = await subject_all();
             const textBookList=await get_text_books();
          //  const textBookList=await get_text_books_subject_id(id);
            setQuestion(questionList?.data);
            setSubject(result?.data);
            setTextBook(textBookList?.data);
        };
        fetchData();
    }, []);
    return (
        <>
            {

                lecture?.map((x, i) => {
                    return (
                        <>
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6" key={i}>
                                            <div className="form-group">
                                                <label>Upload Document {i + 1}</label>
                                                <input type="file" className="form-control"
                                                    name='u_document'
                                                    onChange={(e) => handleLectureImgChange(e, i)}
                                                    accept=".pdf" 
                                                    required/>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Embedded Video Link</label>
                                                <input type="url" className="form-control"
                                                    placeholder='Enter Link'
                                                    value={x.link}
                                                    name='link'
                                                    onChange={(e) => handleLectureInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Image</label>
                                                <input type="file" className="form-control"
                                                    name='u_image'
                                                    onChange={(e) => handleLectureImgChange(e, i)} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Audio</label>
                                                <input type="file" className="form-control"
                                                    name='u_audio'
                                                    onChange={(e) => handleLectureImgChange(e, i) }
                                                    accept='audio/*'
                                                     />

                                            </div>
                                        </div>
                                        <div className="col-md-6" >
                                            <div className="form-group">
                                                <label>Unit </label>
                                                <select className="form-control form-select"
                                                    value={x.unit} name='unit'
                                                    onChange={(e) => handleLectureInputChange(e, i)} required>
                                                    <option value="">Choose Unit</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Week</label>
                                                <input type="text" className="form-control"
                                                    placeholder='Enter Week'
                                                    value={x.week}
                                                    name='week'
                                                    onChange={(e) => handleLectureInputChange(e, i)}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-md-6" >
                                            <div className="form-group">
                                                <label>Questionaire</label>
                                                <select className="form-control form-select"
                                                    value={x.q_id}
                                                    name='q_id'
                                                    onChange={(e) => handleLectureInputChange(e, i)} >
                                                    <option value="">Choose Question</option>
                                                    {
                                                        question && question?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.question_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <select className="form-control form-select"
                                                    name='subject_id'
                                                    value={x.subject_id}
                                                    onChange={(e) => handleLectureInputChange(e, i)}
                                                    required
                                                    >
                                                    <option value="">Choose Subject</option>
                                                    {
                                                        subjects && subjects?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.subject}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Text Book</label>
                                                <select className="form-control form-select"
                                                    name='text_book_id'
                                                    value={x.text_book_id}
                                                    onChange={(e) => handleLectureInputChange(e, i)}>
                                                    <option value="">Choose text book</option>
                                                    {
                                                        textBook && textBook?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea type="text" className="form-control"
                                                    placeholder='Enter Description'
                                                    value={x.description}
                                                    name='description'
                                                    onChange={(e) => handleLectureInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className='add_btn'>
                                        {
                                            lecture.length !== 1 && (
                                                <button className='btn-warning' onClick={() => handleLectureRemoveClick(i)}>Remove</button>
                                            )
                                        }
                                        {
                                            lecture.length - 1 === i && (
                                                <button className='btn-success' onClick={() => handleLectureAddClick()}>Add</button>
                                            )
                                        }
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </>
                    )
                })
            }

        </>
    )
}
export default AddLecture;






