import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

// add item
export async function add_question(data) {
    try {
        var requestOptions = {
            method: 'POST',
            body: data,
            redirect: 'follow'
        };
        const response = await fetch(HTTPURL + 'api/question-add',requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// export async function get_add_question() {
//     try {
//         const response = await fetch(HTTPURL + 'api/question-list');
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         return error.message;
//     }
// }

export async function get_add_question(data) {
    try {
        var requestOptions = {
            method: 'POST',
            body: data,
            redirect: 'follow'
        };
        const response = await fetch(HTTPURL + 'api/question-list',requestOptions);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// get all items
export async function get_question() {
    try {
        const response = await fetch(HTTPURL + 'api/questions-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

export async function get_question_detail(id) {
    try {
        const response = await fetch(HTTPURL + `api/preview-questionnaire/?id=${id?.id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}




// class-list edit 
export async function question_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/question-edit/?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list update 
export async function question_update(id, data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };


    try {
        const response = await fetch(HTTPURL + `api/question-update/?id=${id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list delete
export async function question_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/question-destroy?id=${id}`)
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function getquestionList() {
    try {
        const response = await fetch(HTTPURL + 'api/student/get-question-year'
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}









// get all question by questionnaire type 
export async function get_question_by_type(payload) {
    try {
        const response = await fetch(HTTPURL + `api/filter-questions-all?questionnaire_type=${payload?.questionnaire_type}&grade_id=${payload?.grade_id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}