import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { branch_add } from '../../../api/branches/Branch';
const AddBrance = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await branch_add(data);
        if (result.status) {
            return navigate('/branch/all');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Branch</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Branch Info</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Paypal Payment eInformtion</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Code</labell>
                                            <input type="text" className="form-control"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register('branch_code', { required: true })}
                                            />
                                        {errors.branch_code && errors.branch_code.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Branch Name</labell>
                                            <input type="text" className="form-control"
                                                {...register('branch_name', { required: true })}
                                            />
                                        {errors.branch_name && errors.branch_name.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Branch Addresss</label>
                                            <input type="text" className="form-control"
                                                {...register('branch_addresss', { required: true })}
                                            />
                                        {errors.branch_addresss && errors.branch_addresss.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Branch Contact Number</label>
                                            <input type="text" className="form-control"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                maxlength="10"
                                                {...register('Branch_contact_number', { required: true })}
                                            />
                                        {errors.Branch_contact_number && errors.Branch_contact_number.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Api key</labell>
                                            <input type="text" className="form-control"
                                                {...register('api_key')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <labell>Secret key</labell>
                                            <input type="text" className="form-control"
                                                {...register('secret_key')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default  AddBrance ;
