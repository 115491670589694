import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { get_states, term_add } from "../../../apis/Common";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../Utils/Utils";
import { state_branch_all } from "../../../apis/Branches";
const AddTerms = () => {
  const [checkValidation, setCheckValidation] = useState(true);
  const [stateID, setStateId] = useState("");
  const [statelist, setStateList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors,isDirty  },
    reset,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
// check for min max year
  const today = new Date().toISOString().split("T")[0];
  //const maxDate = `${ new Date().getFullYear()}-12-31`;
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // const formattedStartDate = formatDate(data.start_date);
    // const formattedEndDate = formatDate(data.end_date);
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    const weeksBetweenDates = Math.round(
      (endDate - startDate) / (7 * 24 * 60 * 60 * 1000)
    );
    const formattedData = {
      ...data,
      start_date: data.start_date,
      end_date: data.end_date,
      max_week: weeksBetweenDates,
    };
    // if(!checkValidation){
    //     console.log('not valid');
    // }else{
    let result = await term_add(formattedData);
    if (result.status) {
      return navigate("/admin/general-setting/term/all");
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const branchList = await state_branch_all(stateID);
      setBranchList(branchList?.data);
    };
    fetchData();
  }, [stateID]);
  // }
  // const regexPattern = /^term\s?(1[0-2]|[1-9])$/;
  // const checkregex =(name)=>{
  //     if (regexPattern.test(name)) {
  //         console.log("Valid string");
  //         setCheckValidation(true);
  //     } else {
  //         setCheckValidation(false);
  //     }
  // }
  useEffect(() => {
    const fetchData = async () => {
      const stateList= await get_states();
      setStateList(stateList?.data);
    };
    fetchData();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Terms</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/general-setting/term/all`}
                onClick={(e) => {
                    handleNavigation(e,isDirty)
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Term Name *</label>
                  {/* <select className="form-control" name="name" {...register('name',{ required: true })}>
                                        <option value="">Choose Term</option>
                                        <option value="1">Term 1</option>
                                        <option value="2">Term 2</option>
                                        <option value="3">Term 3</option>
                                        <option value="4">Term 4</option>
                                    </select> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Term Name"
                    name="name"
                    {...register("name", { required: true })}
                  />
                {errors.name && errors.name.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
                </div>
                {/* {checkValidation ? '' : <span className="validation-error">Term name should be term and number between 0 to 12</span>} */}
              </div>
              <div className="col-md-6">
                  <div className="form-group">
                    <label>State *</label>
                    <select
                      className="form-select form-control"
                      {...register("state", { required: "This is required" })}
                      onChange={(e) => setStateId(e.target.value)}
                    >
                      {/* <option value="">Choose State</option>
                      <option value="New South Wales">New South Wales</option>
                      <option value="Australian Capital Territory">
                        Australian Capital Territory
                      </option>
                      <option value="Northern Territory">
                        Northern Territory
                      </option>
                      <option value="Queensland">Queensland</option>
                      <option value="South Australia">South Australia</option>
                      <option value="Tasmania">Tasmania</option>
                      <option value="Victoria">Victoria</option>
                      <option value="Western Australia">
                        Western Australia
                      </option> */}
                       {statelist?.length > 0
                        ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">State not available</option>,
                          ]}
                    </select>
                    {errors.state && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Branch *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("branch")}
                    >
                      {branchlist?.length > 0
                        ? [
                            <option value="">Choose Branch</option>,
                            branchlist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.branch_name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            // <option value="">Choose Branch</option>
                            //   ,
                            <option value="">Branch not available</option>,
                          ]}
                    </select>
                  </div>
                </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Start Date *</label>
                  <input
                    type="date"
                    className="form-control"
                    pattern="dd-mm-yyyy"
                    //  max="9999-12-31"
                    placeholder="Enter Start Date"
                    min={today}
                   // max={maxDate}
                    {...register("start_date", {
                      required: "Start date is required!",
                      validate: (value) => {
                        const selectedDate = new Date(value);
                        const todayDate = new Date();
                           return selectedDate > todayDate || "Please select a future date.";
                        // const year = value.split("-")[0];
                        // if (year.length !== 4) {
                        //   return "Year must be 4 digits.";
                        // }
                      },
                    })}
                  />
                {errors.start_date && errors.start_date.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
                {errors.start_date && errors.start_date.type === "validate" && (
                  <span className="validation-error">
                    {errors.start_date.message}
                  </span>
                )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>End Date *</label>
                  <input
                    type="date"
                    className="form-control"
                    pattern="dd-mm-yyyy"
                    placeholder="Enter End Date"
                    min={today}
                  //  max={maxDate}
                    {...register("end_date", {
                      required: "End date is required!",
                      validate: (value) => {
                        const selectedDate = new Date(value);
                        const todayDate = new Date();
                        return (
                          selectedDate > todayDate ||
                          "Please select a future date."
                        );
                      },
                    })}
                  />
                {errors.end_date && errors.end_date.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { AddTerms };