import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { course_all, course_destroy } from '../../../api/Course/Course';
import CustomTable from '../../pages/CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Course',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    },

    // {
    //     name: 'Delete',
    //     selector: 'action',
    //     sortable: true,
    // }
];

const AllCourses = ({}) => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await course_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, status } = elem;
                return {
                    no: `#${index + 1}`,
                    name: name.toLowerCase(),
                    status: status == 0 ? 'Inactive' : 'Active',
                    action: <div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/branch/course/Details/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                    {/* <button className="dlt-btn" onClick={() => destroy(id)}><i class="fa fa-trash" aria-hidden="true"></i></button> */}
                    </div>,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                course_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Course List</h4>
                </div>
                <div className='col-md-8'>
                    {/* <Link className='ui button float-end' to="/branch/course/add">Add Course</Link> */}
                </div>
            </div>
            {
                showResults ?
                    <CustomTable columns={columns} data={data} />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllCourses;


