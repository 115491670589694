import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../../Utils/DataLoader';
import { class_list_all, class_list_destroy } from '../../../../apis/ClassManagment/ClassList';
import CustomTable from '../../CustomTable.jsx';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Branch Name',
        selector: 'branch_name',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Class Name',
        selector: 'name',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllClassList = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userData'));
    useEffect(() => {
        async function get_data() {
            const result = await class_list_all(userData?.data?.id);
            const arrayData = result?.data?.map((elem, index) => {
                const { id, subjects, year, grade, c_name, branch_name,c_id } = elem;
                return {
                    no: `#${index + 1}`,
                    branch_name: branch_name ? branch_name : '-',
                    subject: subjects ? subjects : '-',
                    grade:grade,
                    name: c_name,
                    action:<div data-tag="allowRowEvents">
                    <div className='class-btn'>
                    <Link className='edit-btn' to={`/teacher/class/edit/${c_id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    </div>
                 </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                class_list_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Classes</h4>
                </div>
                <div className='col-md-8'>
                    {/* <Link className='ui button float-end' to="/teacher/admin/class/add">New Class</Link> */}
                </div>
            </div>
            {
                showResults ?

                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllClassList;
