import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add branch
export async function branch_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branch-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all branches
export async function branch_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all roll by branch
export async function role_by_branch(branchId) {
    try {
        const response = await fetch(HTTPURL + `api/role-by-branch?id=${branchId}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
           // toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all branches
export async function state_branch_all(id) {
    try {
        const response = await fetch(HTTPURL + `api/state-branches?branch_state=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all course type
export async function course_type_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/course-type-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all branches
export async function branch_all_data() {
    try {
        const response = await fetch(HTTPURL + 'api/all-branches-data');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// branch edit 
export async function branch_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/branch-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status === true)
        {
            // toast.success(responseData?.message);
        }else if (responseData?.status === false){
            toast.success(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch update 
export async function branch_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/branch-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function branch_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/branch-distroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// check branch code exsist
export async function check_branch_exist(payload) 
{
    try {
        const response = await fetch(HTTPURL + `api/branch-code-exist?branch_code=${payload}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

