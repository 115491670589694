import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { discout_destroy, discout_all } from '../../../../apis/Discount'
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'discount_list',
        sortable: true,
    },
    // {
    //     name: 'Fees',
    //     selector: 'discount',
    //     sortable: true,
    // },
    {
        name: 'Percentage',
        selector: 'percentage',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllDiscount = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    useEffect(() => {

        async function get_data() {
            const result = await discout_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, fees,status, percentage } = elem;
                return {
                    no: `#${index + 1}`,
                    discount: `AUD ${fees}`,
                    discount_list: name,
                    percentage: `${percentage}%`,
                    status: status == '0' ? 'INACTIVE' : 'ACTIVE',
                    action: <div data-tag="allowRowEvents">
                        <Link class="edit-btn" to={`/admin/general-setting/discount/edit/${id}`}>
                            <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button></div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                discout_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Discount List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/general-setting/discount/add">Add Discount
                    </Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllDiscount;


