import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { SubCategory_update, SubCategory_edit } from '../../../../apis/Catergory';
import { get_SubCategory,get_Category } from '../../../../apis/Catergory'
import { Button } from 'semantic-ui-react';
const EditSubCategory = ({ isModalOpen, setIsModalOpen, duplicateItemId, status, setStatus }) => {
    const [checkValidation, setCheckValidation] = useState(true);
    const [category,setCategory]=useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

        //grade get data api
        useEffect(()=>{
            async function get_data() {
                const result = await SubCategory_edit(duplicateItemId);
                if (result?.data?.length > 0) {
                    const { name, status ,category_id} = result?.data[0];
                    setValue('name', name);
                    setValue('status', status);
                    setValue('category_id',category_id)
                            }
            }
            if (isModalOpen) {
            get_data();
        }
        },[isModalOpen, duplicateItemId])
  
    async function getData() {
        const result = await get_SubCategory();
    }
    useEffect(() => {
        const fetchData = async () => {
            const category=await get_Category();
            setCategory(category?.data);
        };
        fetchData();
        getData();
        return (
            setIsModalOpen(false)
        )
    }, []);
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
            let result = await SubCategory_update(duplicateItemId, data);
            if (result?.status) {
                { setIsModalOpen(false); setStatus("") }
                window.location.reload();
                // getData();
                // return navigate('/admin/grades/all');
            
        }
        setIsSubmitting(false);

    }
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Edit Data</h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)} >
                                <div className='row'>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label>Category *</label>
                                    <select className="form-control form-select"  name="name"  {...register('category_id', { required: true })} >
                                        <option value="">Choose Category</option>
                                        {
                                            category && category?.map((elem) => {
                                                return <option value={elem?.id}>{elem?.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.category_id && errors.category_id.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Sub Category Name *</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Sub Category Name'
                                                {...register('name', { required: true })}
                                            />
                                        {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group modal-group-n ">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!checkValidation?
                                     <Button type='submit' className="btn button" data-bs-dismiss="modal" disabled>Save</Button>:
                                     <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>}
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditSubCategory;


