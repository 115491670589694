import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { branch_all, role_by_branch } from "../../../../apis/Branches";
import { handleNavigation } from "../../../../Utils/Utils";
import {
  sub_users_edit,
  sub_users_update,
} from "../../../../apis/generalSetting/SubUsers";
const EditSubUsers = () => {
  const [payment, setPayment] = useState(false);
  const [branch, setBranch] = useState(false);
  const [rolelist,setRoleList] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [classes, setClasses] = useState(false);
  const [branchlist,setBranchList] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors,isDirty },
    setValue,
    reset,
  } = useForm();

  const id = useParams();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log(data,"132432");
    
    let result = await sub_users_update(id, data);
    if (result.status) {
      return navigate("/admin/administrator-setting/sub-users/all");
    }
    reset();
  };

  const fetchData = async () => {
      const data=await branch_all();
    let res = await sub_users_edit(id?.id);
    setValue("first_name", res?.data[0]?.first_name);
    setValue("last_name", res?.data[0]?.last_name);
    setValue("mobile", res?.data[0]?.mobile);
    setValue("status", res?.data[0]?.status.toString());
    setValue("email", res?.data[0]?.email);
    
  setTimeout(()=>{
    setBranchId(res?.data[0]?.branch_id)
      setValue('branch_id',res?.data[0]?.branch_id);
  },1000)
    setPayment(res?.data[0]?.payment ? true : false);
    setBranch(res?.data[0]?.branch ? true : false);
    setClasses(res?.data[0]?.c_name ? true : false);
    setBranchList(data?.data)
  };

  useEffect(() => {
    const fetchs = async () => {
      const res=await branch_all();
        const roleList = await role_by_branch(branchId)
        setBranchList(res?.data)
        setRoleList(roleList?.data)
    }
    fetchs();
  }, [branchId]);

  useEffect(() => { 
   
    fetchData();
  }, []);

  const handleSelectRole =(id) => {
    setSelectedRoleId(Number(id))
    let selected = rolelist && rolelist.find((item)=> item.role_id === Number(id));
    if(selected){
      setValue('role_id', selected.role_id)
      setValue('role', selected.role)

    }
  }
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-users">
              <h4 className="modal-title">Edit User</h4>
              <Link
                className="edit-btn"
                to={`/admin/administrator-setting/sub-users/all`}
                onClick={(e) => {handleNavigation(e,isDirty) }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="permission-sec-n">
              {/* <h4 className="modal-title">Permissions</h4> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>First Name *</label>
                            <input
                              type="text"
                              placeholder="Enter User First Name"
                              className="form-control"
                              {...register("first_name", { required: true })}
                            />
                          {errors.first_name &&
                            errors.first_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              placeholder="Enter User Last Name"
                              className="form-control"
                              {...register("last_name", { required: true })}
                            />
                          {errors.last_name &&
                            errors.last_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email *</label>
                            <input
                              type="email"
                              placeholder="Enter User Email"
                              className="form-control"
                              {...register("email", { required: true })}
                            />
                            {errors.email &&
                            errors.email.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="text"
                                                placeholder='Enter User Password'
                                                className="form-control" {...register('password')}
                                            />
                                        </div>
                                    </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Mobile No *</label>
                            <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                            <input
                              type="text"
                              placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                              className="form-control"
                              {...register("mobile", { 
                                required: "Mobile number  is required",
                          pattern: { value: /^\d{10}$/, message: "mobile number must be 10 digits" },
                          validate: (value) =>
                            /^\d+$/.test(value) || "Only numbers are allowed",
                         })}
                         maxLength={10}
                         />
                         </div>
                          {errors.mobile && (
                              <span className="validation-error">
                                 {errors.mobile.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Branch *</label>
                            <select
                              className="form-select"
                              {...register("branch_id",{required:'true'})}
                              onChange={(e) => setBranchId(e.target.value)}
                            >
                              <option value="">Choose a branch</option>
                              {branchlist?.length > 0 ? (
                                branchlist &&
                                branchlist?.map((elem) => {
                                  return (
                                    <option value={elem?.id}>
                                      {elem?.branch_name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option>No result</option>
                              )}
                            </select>
                            {errors.branch_id &&
                            errors.branch_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group  add_new">
                            <label>Roles *</label>
                            <Link href="" title="Add new role" className="add_role">Add new role</Link>
                            <select
                              className="form-select"
                              {...register("role_id",{required:'true'})}
                              value={selectedRoleId}
                              onChange={(e)=> handleSelectRole(e.target.value)}
                            >
                               {rolelist?.length > 0
                        ? [
                            <option value="">Choose Role</option>,
                            rolelist?.map((elem) => {
                              return (
                                <option value={elem?.role_id}>
                                  {elem?.role}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">Role not available</option>,
                          ]}
                            </select>
                            {errors.role_id &&
                            errors.role_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>




                        <div className="col-md-4">
                          <div className="form-group ">
                            <label>Status *</label>
                            <select
                              className="form-select"
                              {...register("status")}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                            {errors.status &&
                            errors.status.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>status</label>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                {...register("status", { required: true })}
                                id="inlineRadio1"
                                value={1}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Active
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                {...register("status", { required: true })}
                                id="inlineRadio2"
                                value={0}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Inactive
                              </label>
                            </div>
                          {errors.status &&
                            errors.status.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="col-md-6 checkbox-n">
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={payment}
                            onChange={handleChange}
                            checked={payment}
                          />{" "}
                          <span className="modal-title">Payment</span>
                        </div>
                        <div className="col-md-12">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={classes}
                            onChange={handleChange1}
                            checked={branch}
                          />{" "}
                          <span className="modal-title">Branch</span>
                        </div>
                        <div className="col-md-12">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={payment}
                            checked={classes}
                            onChange={handleChange2}
                          />{" "}
                          <span className="modal-title">Classes</span>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditSubUsers;
