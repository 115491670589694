import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";
// add teacher
export async function teacher_add(data) {
    try {
        const local = `http://127.0.0.1:4040/`;
        const response = await fetch(HTTPURL + 'api/teacher-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all teacher
export async function teacher_all(b_id) {
    try {
        const response = await fetch(HTTPURL + `api/teachers-all?b_id=${b_id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;  
    }
}
// teacher edit 
export async function teacher_edit(id)
{
    try {
        const local = `http://127.0.0.1:4040/`;
        
        const response = await fetch(HTTPURL + `api/teacher-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// teacher update 
export async function teacher_update(id, data)
{
    try {
        const local = ``;
        const response = await fetch(HTTPURL + `api/teacher-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// teacher delete
export async function teacher_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/teacher-destroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
