import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {check_exit_id,get_countries,member_add,} from "../../../apis/studentManagement/StudentManagement";
import {branch_all,course_type_all,state_branch_all,} from "../../../apis/Branches";
import { grade_all } from "../../../apis/Grade";
import { get_course_terms, get_days, get_states, get_terms } from "../../../apis/Common";
import { select_course_all_by_gradeID } from "../../../apis/Course.jsx";
import Select from "react-select";
import { handleNavigation } from "../../../Utils/Utils.js";
const AddMembers = () => {
  const [course, setCourse] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [statelist, setStateList] = useState([]);
  const [coursetypeList, setCoursetypeList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [days, setDays] = useState([]);
  const [termData, setTermData] = useState([]);
  const [showMsg, setShowMsg] = useState("");
  const [checkUserID, setCheckUserId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkAvailability, setCheckAvailability] = useState(false);
  const [gradeID, setGradeId] = useState("");
  const [stateID, setStateId] = useState("");
  const [courseID, setCourseID] = useState("");
  const [selectedCourse, setSelectedCourse] = useState([]);
  const {
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { errors,isDirty },
    watch,
    trigger,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const password = useRef({});
  const classroom_gmail = useRef({});
  const p_email = useRef({});
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();

    // Calculate max date (3 years ago from today)
    const maxDateCalc = new Date(
      today.getFullYear() - 0,
      today.getMonth(),
      today.getDate()
    );

    // Calculate min date (16 years ago from today)
    const minDateCalc = new Date(today.getFullYear() - 20, 0, 2); // January 1 of the current year

    // Convert dates to string format 'YYYY-MM-DD' for the input field
    setMaxDate(maxDateCalc.toISOString().split("T")[0]);
    setMinDate(minDateCalc.toISOString().split("T")[0]);
  }, []);

  password.current = watch("password", "");
  classroom_gmail.current = watch("classroom_gmail", "");
  p_email.current = watch("p_email", "");

  console.log("state", getValues("country")?.length);
  if (getValues("country")?.length == 0) {
    setValue("country", 14);
  }
  const onSubmit = async (data) => {
    data.course = selectedCourse;
    // if(getValues('country')?.length==0){
    //     setValue('country',14)
    // }
    if (isSubmitting) return;
    setIsSubmitting(true);

    data.pc_email = "";
    let result = await member_add(data);
    if (result.status) {
      return navigate("/admin/students/members");
    }
    setIsSubmitting(false);
  };
  const check_User_id = async () => {
    const emailValue = getValues("email");

    if (!emailValue) {
      toast.error("Please enter user id");
    } else {
      if (checkAvailability) return;
      setCheckAvailability(true);
      let res = await check_exit_id(emailValue);
      if (res) {
        setShowMsg(res?.message);
      }
    }
    setCheckAvailability(false);
  };
  console.log("******stateid", stateID);

  useEffect(() => {
    const fetchData = async () => {
      // const branchList = await branch_all();
      const branchList = await state_branch_all(stateID);
      const courseTypeList = await course_type_all();
      const gradeList = await grade_all();
      const countryList = await get_countries();
      const days = await get_days();
      const course = await select_course_all_by_gradeID(gradeID);
      const stateList= await get_states();
      const data = course?.data?.map((item) => ({
        value: item?.id,
        label: item.name,
      }));
      const terms = await get_terms();
      //const terms = await get_course_terms(courseID);
      setBranchList(branchList?.data);
      setStateList(stateList?.data);
      setCoursetypeList(courseTypeList?.data);
      setGradeList(gradeList?.data);
      setCountries(countryList?.data);
      setDays(days?.data);
      setCourse(data);
      setTermData(terms?.data);
    };
    fetchData();
  }, [stateID]);
  useEffect(() => {
    const fetchData = async () => {
      const course = await select_course_all_by_gradeID(gradeID);
      const data = course?.data?.map((item) => ({
        value: item?.id,
        label: item.name,
      }));
      setCourse(data);
    };
    fetchData();
  }, [gradeID]);
  useEffect(() => {
    const fetchData = async () => {
      const terms = await get_terms();
      setTermData(terms?.data);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log("******!@#$", courseID);

  //     const terms = await get_course_terms(courseID);
  //     setTermData(terms?.data);
  //   };
  //   fetchData();
  // }, [courseID]);
  //multiple course select
  const handleSubChange = (selectedSubOptions) => {
    setSelectedCourse(selectedSubOptions);
  };
  console?.log("-------", course);
  return (
    <>
      <div className="right-nav" id="dash-event">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create New Account</h4>
            <Link
              className="edit-btn"
              to={`/admin/students/members`}
              onClick={(e) => {handleNavigation(e,isDirty)}}
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </Link>
          </div>
          <div className="modal-body">
            <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">
                {/* <hr className='mt-3' /> */}
                <div className="form-group">
                  <label>
                    <b>Student information</b>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder="Enter First Name"
                      {...register("first_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.first_name && errors.first_name && (
                      <span className="validation-error">
                        {errors.first_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder="Enter Last Name"
                      {...register("last_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.last_name && (
                      <span className="validation-error">
                        {errors.last_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("gender", { required: true })}
                    >
                      <option value="">Choose Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.gender && errors.gender.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date of Birth *</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_ob"
                      max={maxDate}
                      min={minDate}
                      {...register("date_of_birth", {
                        required: "Date of birth is required",
                        validate: (value) => {
                          const today = new Date();
                          const birthDate = new Date(value);
                          const age =
                            today.getFullYear() - birthDate.getFullYear();

                          return (
                            (age >= 0 && age <= 20) ||
                            "Age must be below 20 years"
                          );
                        },
                      })}
                      // onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                    />
                    {errors.date_of_birth && (
                      <span className="validation-error">
                        {errors.date_of_birth.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>School Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="schoole_name"
                      placeholder="Enter School Name"
                      {...register("school", {
                        required: "This is required",
                      })}
                    />
                    {errors.school && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      placeholder="Enter Address"
                      {...register("address", {
                        required: "This is required",
                      })}
                    />
                    {errors.address && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State *</label>
                    <select
                      className="form-select form-control"
                      {...register("state", { required: "This is required" })}
                      onChange={(e) => setStateId(e.target.value)}
                    >
                      {/* <option value="">Choose State</option>
                      <option value="New South Wales">New South Wales</option>
                      <option value="Australian Capital Territory">
                        Australian Capital Territory
                      </option>
                      <option value="Northern Territory">
                        Northern Territory
                      </option>
                      <option value="Queensland">Queensland</option>
                      <option value="South Australia">South Australia</option>
                      <option value="Tasmania">Tasmania</option>
                      <option value="Victoria">Victoria</option>
                      <option value="Western Australia">
                        Western Australia
                      </option> */}
                        {statelist?.length > 0
                        ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">State not available</option>,
                          ]}
                    </select>
                    {errors.state && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Branch</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("branch")}
                    >
                      {branchlist?.length > 0
                        ? [
                            <option value="">Choose Branch</option>,
                            branchlist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.branch_name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            // <option value="">Choose Branch</option>
                            //   ,
                            <option value="">Branch not available</option>,
                          ]}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Grade *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("grade", {
                        required: "This is required",
                      })}
                      onChange={(e) => setGradeId(e.target.value)}
                    >
                      <option value="">Choose Grade</option>
                      {gradelist?.length > 0 ? (
                        gradelist.map((elem) => (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        ))
                      ) : (
                        <option>No result</option>
                      )}
                      <option value="other">Other</option>
                    </select>
                    {gradeID
                      ? ""
                      : errors.grade && (
                          <span className="validation-error">
                            {errors.grade.message}
                          </span>
                        )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Classroom Gmail:*</label>
                                        <input type="text" className="form-control" name="venue_name"
                                        placeholder='Enter Gmail'
                                            {...register("classroom_gmail",
                                                {
                                                    required: true,
                                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })
                                            }
                                        />
                                        {errors.classroom_gmail && errors.classroom_gmail.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.classroom_gmail && errors.classroom_gmail.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                    </div>
                                </div> */}

                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Classroom Gmail:*</label>
                                        <input type="text" className="form-control" name="venue_name"
                                        placeholder='Enter Classroom Gmail'
                                            {...register("c_classroom_gmail",
                                                {
                                                    required: true,
                                                    validate: value =>
                                                    value === classroom_gmail.current || "This email do not match!",
                                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })
                                            }
                                        />
                                        {errors.c_classroom_gmail && errors.c_classroom_gmail.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.c_classroom_gmail && errors.c_classroom_gmail.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                        {errors.c_classroom_gmail && <p className="validation-error">{errors.c_classroom_gmail.message}</p>}
                                    </div>
                                </div> */}
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Studying at CS Education?</label>
                                        <div className='highlight_inner'>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_offline" value="1"
                                                    {...register('cs_education')}
                                                    onChange={() => setShow(true)}
                                                /> Yes </label>
                                            </div>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_online" value="0"
                                                    {...register('cs_education')}
                                                    onChange={() => setShow(false)}
                                                /> No </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {show ? <div className="col-md-6">
                                    <div className="form-group">
                                        <label>If yes, Student Number:</label>
                                        <input type="text" className="form-control" name="venue_name"
                                            placeholder='Enter Student No.'
                                            {...register('student_number')}
                                        />
                                    </div>
                                </div>
                                    : ''} */}

                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Course Type</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("subject_id")}
                    >
                      <option value="">Choose course Type</option>
                      {coursetypeList?.length > 0 ? (
                        coursetypeList &&
                        coursetypeList?.map((elem) => {
                          return (
                            <option value={elem?.id}>
                              {elem?.category_name}
                            </option>
                          );
                        })
                      ) : (
                        <option>No result</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Course *</label>
                    {/* <select
                      className="form-select form-control"
                      {...register("course", {
                        required: true,
                      })}
                      onChange={(e)=>setCourseID(e.target.value)}
                    >
                      <option value="">Choose Course </option>
                      {course &&
                        course?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select> */}
                    <Controller
                      name="course"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          options={course}
                          // className="form-control"
                          placeholder="Choose Course"
                          isMulti
                          {...field}
                          value={selectedCourse}
                          onChange={(otopn) => handleSubChange(otopn)}
                        />
                      )}
                    />
                    {errors.course && errors.course.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>

                {/* <div className={show ? `col-md-3` : `col-md-4`}>
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-select form-control" {...register('type')}>
                                            <option value="">Choose Type</option>
                                            <option value="C1">C1</option>
                                            <option value="C2">C2</option>
                                            <option value="C3">C3</option>
                                        </select>
                                    </div>
                                </div> */}
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Term *</label>
                    <select
                      className="form-select form-control"
                      {...register("term_id", {
                        required: true,
                      })}
                    >
                      <option value="">Choose Term </option>
                      {termData &&
                        termData?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select>
                    {errors.term_id && errors.term_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Day </label>
                    <select
                      className="form-select form-control"
                      {...register("day")}
                    >
                      <option value="">Choose Day</option>
                      {days &&
                        days?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 2. Parent information</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="venue_name"
                      placeholder="Enter Parent First Name"
                      {...register("p_first_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.p_first_name && (
                      <span className="validation-error">
                        {errors.p_first_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="venue_name"
                      placeholder="Enter Parent Last  Name"
                      {...register("p_last_name", {
                        required: "This is required",
                        validate: (value) =>
                          /^[A-Za-z\s]+$/.test(value) ||
                          "Only letters are allowed",
                      })}
                    />
                    {errors.p_last_name && (
                      <span className="validation-error">
                        {errors.p_last_name.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Telephone</label>
                                        <input type="text" className="form-control" name="offine_address"
                                            placeholder='Enter Telephone No.'
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('p_mobile_phone')}
                                        />
                                    </div>
                                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Primary contact *</label>
                    <div className="input-group branch-prepend">
                      <div className="input-group-prepend">
                        <div className="input-group-text">+61</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="offine_seats"
                        {...register("mother_mobilenumber", {
                          required: "Mobile number  is required",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "mobile number must be 10 digits",
                          },
                          validate: (value) =>
                            /^\d+$/.test(value) || "Only numbers are allowed",
                        })}
                        maxLength={10}
                      />
                    </div>
                    {errors.mother_mobilenumber && (
                      <span className="validation-error">
                        {errors.mother_mobilenumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Alternative Contact </label>
                    <div className="input-group branch-prepend">
                      <div className="input-group-prepend">
                        <div className="input-group-text">+61</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="online_url"
                        placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("father_mobilenumber", {
                          pattern: {
                            value: /^\d{10}$/,
                            message: "Mobile number must be 10 digits",
                          },
                          validate: {
                            isNumeric: (value) =>
                              value === "" ||
                              /^\d+$/.test(value) ||
                              "Mobile number must be numeric",
                          },
                        })}
                        maxLength={10}
                      />
                    </div>
                    {errors.father_mobilenumber && (
                      <span className="validation-error">
                        {errors.father_mobilenumber.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control" name="online_seats"
                                            placeholder='Enter Address'
                                            {...register('address')} />
                                    </div>
                                </div> */}
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country (*)</label>
                                        <select className="form-select form-control" {...register('country', { required: true })}>
                                            <option value="">Choose Country</option>
                                            {
                                                countries?.length > 0 ?
                                                    countries && countries?.map((elem) => {
                                                        const selectedVal = `${elem?.id === 14 ? 'selected' : ''}`;
                                                        return (
                                                            <option value={elem?.id || 14} selected={selectedVal}>{elem?.name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option>No result</option>
                                            }
                                        </select>
                                    </div>
                                    {errors.country && errors.country.type === "required" && <span className="validation-error">This is required</span>}
                                </div> */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label>City *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                      name="description"
                      {...register("city", { required: true })}
                    />
                    {errors.city && errors.city.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Post Code:</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter Post Code'
                                            name="description" {...register('post_code')} />
                                    </div>
                                </div> */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Parent's Email *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Parent Email"
                      name="p_email"
                      {...register("p_email", {
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {errors.p_email && errors.p_email.type === "required" && (
                      <span className="validation-error">
                        Parent's email is required
                      </span>
                    )}
                    {errors.p_email && (
                      <span className="validation-error">
                        {errors.p_email.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Parent's Email: *</label>
                                        <input type='text' className="form-control"
                                        placeholder='Enter Confirm Parent Email'
                                        name="description" {...register('pc_email', { required: true,validate: value =>
                                                    value === p_email.current || "This email do not match!" })} />
                                        {errors.pc_email && errors.pc_email.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.pc_email && errors.pc_email.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                        {errors.pc_email && <p className="validation-error">{errors.pc_email.message}</p>}
                                    </div>
                                </div> */}
                <hr className="mt-3" />
                <div className="form-group">
                  <label>
                    <b>Step 3. Sibling Information</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sibling</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Sibling"
                      name="description"
                      {...register("sibling")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sibling Grade</label>
                    <select
                      className="form-control"
                      name="grade"
                      {...register("sibling_grade")}
                    >
                      <option value="">Choose Grade</option>
                      {gradelist?.length > 0 ? (
                        gradelist.map((elem) => (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        ))
                      ) : (
                        <option>No result</option>
                      )}
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 4. Choose a user name and password</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label>User Name(Id): *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="off"
                      placeholder="Enter User Email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                      onChange={(e) => {
                        setCheckUserId(e.target.value);
                      }}
                    />
                    {!checkUserID &&
                      errors.email &&
                      errors.email.type === "required" && (
                        <span className="validation-error">
                          Email is required
                        </span>
                      )}
                    {errors.email && (
                      <span className="validation-error">
                        {errors.email.message}
                      </span>
                    )}
                    <div
                      style={
                        showMsg === "It is available!"
                          ? { color: "#076905" }
                          : { color: "#910404" }
                      }
                      className="col-md-12  text-transform-none"
                    >
                      {showMsg}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div
                      className="ui button mt-4"
                      onClick={() => check_User_id()}
                      // disabled={checkAvailability}
                    >
                      Check Availability of this Email ID
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password: *</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      autocomplete="new-password"
                      placeholder="Enter User Password"
                      {...register("password", {
                        required: true,
                        // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                      })}
                    />
                    {/* {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>} */}
                    {errors.password && errors.password.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Confirm Password: *</label>
                    <input
                      type="password"
                      placeholder="Enter User Confirm Password"
                      className="form-control"
                      name="confirmPassword"
                      {...register("confirmPassword", {
                        required: true,
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    {errors.confirmPassword && (
                      <p className="validation-error">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">
                Check a user name(EMAIL) you want to use
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label>Email</label>
                  <input
                    className="form-control"
                    value={checkUserID}
                    onChange={(e) => setCheckUserId(e.target.value)}
                    name="user_id"
                    required
                  />
                  <br />
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="ui button"
                      onClick={() => check_User_id()}
                    >
                      Check!!
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 text-center text-transform-none">
                  {showMsg}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddMembers;
